import React, { useContext } from "react";
import { TNavigationItem } from "../../../features/Settings/types";
import { iconMap } from "../../../features/Settings/navigation";
import s from "./MiniNav.module.scss";
import Biz from "../../Biz/Biz";
import uuid from "react-uuid";
import AuthContext from "../../../core/context/AuthContext";
interface IMiniNav {
  navigation: TNavigationItem[];
  action: any;
  setAction: (arg: any) => void;
}

const MiniNav = ({ navigation, action, setAction }: IMiniNav) => {
  console.log(action, "action");
  const { user } = useContext(AuthContext);
  return (
    <div className={s.container}>
      {navigation.map((item) => (
        <div
          onClick={() => setAction(item)}
          className={`${s.nav_item} ${
            item.premium && user?.subscription?.plan?.level === 0 ? s.disabled : ""
          } ${action.link === item.link ? s.active : ""}`}
          key={uuid()}
        >
          {React.createElement(iconMap[item.ava])}
          <span>{item.name}</span>
          {item?.premium && user?.subscription?.plan?.level === 0 ? <Biz /> : null}
        </div>
      ))}
    </div>
  );
};

export default MiniNav;
