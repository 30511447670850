import { fabric } from "fabric";
import updateClipPath from "../UpdateClipPath";
import sortCanvasObjectsByLevel from "../SortCanvasObjectsByLevel";
import { uuidv4 } from "../../../../helper/uuid";

const addWARect = (canvas: any) => {
  if (canvas) {
    let existingWorkingArea = canvas.getObjects().find((obj: any) => obj.id === "workingArea");
    let uuid = uuidv4(); // Generate a new UUID

    // If the working area exists, keep its UUID and remove the old working area object
    if (existingWorkingArea) {
      uuid = existingWorkingArea.uuid; // Keep the existing UUID
      canvas.remove(existingWorkingArea);
    }

    const workingAreaRect = new fabric.Rect({
      id: "workingArea",
      name: "workingArea",
      level: 2,
      width: 150,
      height: 200,
      hasControls: true,
      rx: 0,
      ry: 0,
      hasBorders: true,
      left: 0,
      top: 0,
      originX: "center",
      originY: "center",
      strokeWidth: 2,
      fill: "transparent",
      stroke: "gray",
      type: "rect",
      cornerColor: "#60a1ff",
      borderColor: "#6274854d",
      cornerSize: 13,
      uuid: uuid, // Use the retained or new UUID
    } as any);

    updateClipPath(canvas, workingAreaRect);
    sortCanvasObjectsByLevel(canvas);
    canvas.add(workingAreaRect);
  }
};

export default addWARect;
