export const dummyPapers = [
  {
    imgSize: "A2",
    size: "210 x 297",
    dpi: "@300 DPI mm",
    fileType: "img",
  },
  {
    imgSize: "A3",
    size: "210 x 297",
    dpi: "@300 DPI mm",
    fileType: "img",
  },
  {
    imgSize: "A4",
    size: "210 x 297",
    dpi: "@300 DPI mm",
    fileType: "img",
  },
  {
    imgSize: "A5",
    size: "210 x 297",
    dpi: "@300 DPI mm",
    fileType: "img",
  },
  {
    imgSize: "A6",
    size: "210 x 297",
    dpi: "@300 DPI",
    fileType: "img",
  },
];
