import React, { useContext } from "react";

import FabricJSNew from "./FabricJS/FabricJSNew";
import TopBar from "../../../components/Nav/TopBar/TopBar";
import LeftBar from "../../../components/Nav/LeftBar/LeftBar";

import LoadingState from "../../../components/LoadingState/LoadingState";
import { useTranslation } from "react-i18next";
import { loadingStyle100 } from "../../../helper/customeStyles";

import AppContext from "../../../core/context/AppContext";

const DesignStudio = () => {
  const { t } = useTranslation();
  const { isLoadingDesignStudio } = useContext(AppContext);

  return (
    <>
      <TopBar />
      <div style={{ display: "flex" }}>
        <LeftBar />
        {/* <LoadingState styles={loadingStyle} /> */}
        {isLoadingDesignStudio ? (
          <LoadingState styles={loadingStyle100} text={t("Loading")} />
        ) : (
          <FabricJSNew />
        )}
      </div>
    </>
  );
};

export default DesignStudio;
