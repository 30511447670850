import React from "react";
import s from "./Personalization.module.scss";
import Edit from "../../../components/Edit/Edit";
import { useTranslation } from "react-i18next";
const helper = [
  "Wybrany motyw",
  "Krawędź głównego menu",
  "Kolor tła",
  "Kolor tekstu",
  "Kolor akcentu",
  "Kolor Ostrzeżenia",
  "Kolor Potwierdzenia",
];
const apiResponse = [
  "Videlink Light",
  "5px",
  "#F8F8F8",
  "#575757",
  "#548AD3",
  "#FF4D4D",
  "#7DE857",
];
const Personalization = () => {
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <h3>{t("Identification")}</h3>
      <div className={s.top}>
        <div>
          <div className={s.left}>
            <h4>Logo motyw jasny</h4>
            <div>
              <span>VIDELINK</span>
            </div>
          </div>
          <div className={s.right}>
            <h4>Logo motyw ciemny</h4>
            <div>
              <span>VIDELINK</span>
            </div>
          </div>
        </div>
        <Edit
          handleClick={() => {
            console.log("edit action");
          }}
        />
      </div>
      <h3>Styl motywu</h3>
      <div className={s.bottom}>
        {helper.map((el, i) => (
          <div className={s.row}>
            <span>{el}</span>
            {apiResponse[i].startsWith("#") ? (
              <>
                <div
                  style={{
                    backgroundColor: apiResponse[i],
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    border: "1px solid #E6E6E6",
                  }}
                ></div>
                <span className={s.answer}>{apiResponse[i]}</span>
              </>
            ) : (
              <span className={s.answer}>{apiResponse[i]}</span>
            )}
          </div>
        ))}
        <Edit
          handleClick={() => {
            console.log("edit action");
          }}
        />
      </div>
    </div>
  );
};

export default Personalization;
