import { fabric } from "fabric";
import { reorderCanvasObjects, updateCanvasObjectsList } from "./UpdateCanvasObjectList";
import sortCanvasObjectsByLevel from "./SortCanvasObjectsByLevel";
import updateClipPath from "./UpdateClipPath";

type FabricCanvas = fabric.Canvas;
type FabricObject = fabric.Object;
export const scaleObjectsToFitCanvas = (canvas: any) => {
  if (canvas) {
    const canvasWidth = canvas.getWidth();
    const canvasHeight = canvas.getHeight();

    // Calculate the maximum dimensions the objects should occupy
    const maxWidth = canvasWidth - 2 * 50;
    const maxHeight = canvasHeight - 2 * 50;

    // Get the bounding box of all objects
    const boundingBox = canvas.getObjects().reduce(
      (acc: any, obj: fabric.Object) => {
        acc.left = Math.min(acc.left, obj.left!);
        acc.top = Math.min(acc.top, obj.top!);
        acc.right = Math.max(acc.right, obj.left! + obj.getScaledWidth());
        acc.bottom = Math.max(acc.bottom, obj.top! + obj.getScaledHeight());
        return acc;
      },
      { left: Infinity, top: Infinity, right: -Infinity, bottom: -Infinity }
    );

    const objectsWidth = boundingBox.right - boundingBox.left;
    const objectsHeight = boundingBox.bottom - boundingBox.top;

    // Calculate the scaling factors
    const scaleX = maxWidth / objectsWidth;
    const scaleY = maxHeight / objectsHeight;
    const scale = Math.min(scaleX, scaleY); // Use the smaller scale to fit within the canvas with padding

    // Center the objects within the canvas
    const offsetX = (canvasWidth - objectsWidth * scale) / 2 - boundingBox.left * scale;
    const offsetY = (canvasHeight - objectsHeight * scale) / 2 - boundingBox.top * scale;

    // Scale and reposition all objects on the canvas
    canvas.getObjects().forEach((obj: fabric.Object) => {
      obj.scaleX = obj.scaleX! * scale;
      obj.scaleY = obj.scaleY! * scale;
      obj.left = (obj.left! - boundingBox.left) * scale + offsetX;
      obj.top = (obj.top! - boundingBox.top) * scale + offsetY;
      obj.setCoords();
    });

    canvas.renderAll();
  }
};
export const centerCanvasAtOrigin = (canvas: any) => {
  if (canvas) {
    const center = canvas.getCenter();
    canvas.viewportTransform[4] = center.left;
    canvas.viewportTransform[5] = center.top;

    // canvas.renderAll();
  }
};
const updateClipPaths = (canvas: fabric.Canvas) => {
  canvas.forEachObject((obj) => {
    updateClipPath(canvas, obj);
  });
};
const handleLoadCanvasProject = (canvas: fabric.Canvas, json: any, setCanvasObjectList?: any) => {
  if (canvas && json) {
    canvas.clear();
    canvas.loadFromJSON(json, () => {
      canvas.discardActiveObject(); // Clear selection after loading
      canvas.renderAll();
      sortCanvasObjectsByLevel(canvas);
      const objectList = canvas?.getObjects();
      if (objectList) {
        // Only call setCanvasObjectList if it's a valid function
        if (typeof setCanvasObjectList === "function") {
          setCanvasObjectList(objectList);
        }
      }
      reorderCanvasObjects(canvas, objectList);
      updateClipPaths(canvas);
    });
  }
  // Discard any active object that might have been automatically selected
};

export default handleLoadCanvasProject;
