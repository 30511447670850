import React from "react";
import addObject from "./AddObject";
import addImage from "./AddImage";
import addText from "./AddText";
import { fabric } from "fabric";
import resetCanvas from "../ResetCanvas";
import { toJSON } from "../ToJSON";

export interface IFileInformation {
  fileType: string;
  level: number | null;
  url: string;
  name: string;
}

export interface ITextInformation {
  text: string;
  fontSize: string | number;
  color: string;
  fileType: string;
}

type SetCanvasObjectsFunction = (objects: fabric.Object[]) => void;

type AddFunction = (
  canvas: any,
  fileInformation: IFileInformation | ITextInformation,
  setCanvasObjects: SetCanvasObjectsFunction
) => void;

const addStrategy: Record<string, AddFunction> = {
  svg: addObject,
  img: addImage,
  text: addText,
};

const addGateway = (
  canvas: any,
  fileInformation: IFileInformation | ITextInformation,
  setCanvasObjects: SetCanvasObjectsFunction
): void => {
  //reset canvas state so it loads a new snapshot of the desired canvas, if canvasJSONContext !null
  //it will load it
  if (!fileInformation || !fileInformation.fileType) {
    console.error("File information is missing or does not have a fileType.");
    return;
  }

  // if ("level" in fileInformation && fileInformation.level === 1) {
  //   resetCanvas(canvas);
  // }

  const handler = addStrategy[fileInformation.fileType];

  if (handler) {
    handler(canvas, fileInformation, setCanvasObjects);
  } else {
    console.error(`Unsupported file type: ${fileInformation.fileType}`);
    // handle unsupported file types here (e.g., show an error message to the user)
  }
};

export default addGateway;
