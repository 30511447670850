import React, { useContext, useEffect, useState } from "react";
import TopBar from "../../../components/Nav/TopBar/TopBar";
import LeftBar from "../../../components/Nav/LeftBar/LeftBar";
import { filterVersionToShow } from "../../../helper/filterVersionToShow";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { useLocation } from "react-router-dom";
import { useApiRequest } from "../../../core/api/useApiRequest";
import LoadingState from "../../../components/LoadingState/LoadingState";
import { useTranslation } from "react-i18next";
import FabricJSFreeStudio from "./FabricJS/FabricJSFreeStudio";
import { filterViewToShow } from "../../../components/ProductComponent/filterViewToShow";
import handleLoadCanvasProject from "../fabric-functions/LoadProject";
const loadingStyle = {
  width: "100%",
  marginTop: "28%",
  marginBottom: "28%",
  boxSizing: "border-box",
};

const FreeStudio = () => {
  const { t } = useTranslation();
  const { setActiveGlobalState, canvasContext } = useContext(FabricJSContextNew);
  const { sendRequest, isLoading } = useApiRequest();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const path = location.pathname;
  const editor = path.split("/")[1];
  const locationType = path.split("/")[2];
  const requestToken = path.split("/")[3];
  console.log(location);
  const canvasType = locationType === "product" ? "templates" : "projects";

  const fetchDesignStudioData = async () => {
    // if (!activeGlobalState.fullJSON) return;
    setLoading(true);
    const response: any = await sendRequest("get", `/creator/2d/templates?token=${requestToken}`);

    if (response) {
      if (canvasType === "templates") {
        setActiveGlobalState((prevState: any) => ({
          ...prevState,
          fullJSON: response?.data,
          selectedView: response?.data?.views[0],
        }));
        const productToShow = filterViewToShow(
          response?.data?.views[0]?.uuid,
          response?.data,
          "templates",
          true
        );

        handleLoadCanvasProject(canvasContext, productToShow.canvasJSON);
      } else if (canvasType === "projects") {
        setActiveGlobalState((prevState: any) => ({
          ...prevState,
          fullJSON: response?.data,
          selectedView: response?.data?.versions[0],
        }));
        //since it is a project we pass down view_uuid to filter for layers inside view
        //then add layers from project
        const productToShow = filterVersionToShow(
          response?.data?.versions[0]?.view_uuid,
          response?.data?.versions[0]?.uuid,
          response?.data,
          true
        );
        handleLoadCanvasProject(canvasContext, productToShow.canvasJSON);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editor === "free-editor" && !requestToken) return;
    fetchDesignStudioData();
  }, [location]);

  return (
    <>
      <TopBar />
      {isLoading ? (
        <LoadingState styles={loadingStyle} text={t("Loading")} />
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <FabricJSFreeStudio />
          </div>
        </>
      )}
    </>
  );
};

export default FreeStudio;
