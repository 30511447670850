import React, { useContext, useEffect, useState } from "react";
import s from "./MainNav.module.scss";
import { navigationMenu, iconMap } from "./navigation";
import { LinkItem, TNavigationItem } from "./type";
import AppContext from "../../../core/context/AppContext";
import { Link, useLocation } from "react-router-dom";
import Biz from "../../Biz/Biz";
import AuthContext from "../../../core/context/AuthContext";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NavFolderTreeStructure from "../../FolderComponents/NavFolderTreeStructure/NavFolderTreeStructure";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AnimatePresence, motion } from "framer-motion"; // Import Framer Motion

const variants = {
  enter: (custom: any) => ({
    x: custom > 0 ? 300 : -300,
    opacity: 0,
    width: "100%",
    height: "100%",
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  }),
  center: {
    x: 0,
    opacity: 1,
    width: "100%",
    height: "100%",
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  },
  exit: (custom: any) => ({
    x: custom > 0 ? -300 : 300,
    opacity: 0,
    width: "100%",
    height: "100%",
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  }),
};

const MainNav = () => {
  const { t } = useTranslation();
  const { setFolderPath, openNav, lastDesignStudioLink, setOpenLeftBar } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const [openFolderStructure, setOpenFolderStructure] = useState<boolean>(false);

  const [selectedNavLink, setSelectedNavLink] = useState<string>("");
  const [selectedNavLinkRouterPath, setSelectedNavLinkRouterPath] = useState<string>("");

  const designStudioLink = lastDesignStudioLink || "design-studio";
  const [selectedFolder, setSelectedFolder] = useState<any[]>([]);

  const handleNavClick = (itemName: string) => {
    setFolderPath([]);
    // Add any additional logic you want to execute for all nav clicks
  };

  const openFolderStructureHandler = (navName: string) => {
    setSelectedNavLink(navName);
    if (navName === "Products") {
      setOpenFolderStructure(true);
      setSelectedNavLinkRouterPath("templates");
    } else {
      setOpenFolderStructure(true);
      setSelectedNavLinkRouterPath(navName.toLowerCase());
    }
  };

  const closeFolderStructureHandler = () => {
    setOpenFolderStructure(false);
  };
  const [activeLink, setActiveLink] = useState<string>("Dashboard");
  useEffect(() => {
    setOpenLeftBar(false);
  }, []);

  const renderLink = (item: LinkItem, i: number) => {
    const Icon = item.ava ? iconMap[item.ava] : null;
    const isDisabled = user?.subscription?.plan?.level === 0 && item.biz;

    return (
      <div className={`${s.nav_item} ${activeLink === item.name ? s.active : ""}`} key={i}>
        {!openFolderStructure ? (
          <Link
            to={item.link}
            className={isDisabled ? `${s.disabled}` : ""}
            onClick={() => {
              handleNavClick(item.name);
              setActiveLink(item.name);
            }}
          >
            {Icon && <Icon className={s.icon} fontSize="medium" />}
            {t(item?.name)}
            {item?.biz && user?.subscription?.plan?.level === 0 ? <Biz /> : null}
            {item?.hasFolderStructure && activeLink === item?.name ? (
              <ChevronRightIcon
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigation
                  openFolderStructureHandler(item?.name);
                }}
                sx={{ marginLeft: "auto" }}
              />
            ) : null}
          </Link>
        ) : null}
      </div>
    );
  };

  function isLinkItem(item: TNavigationItem): item is LinkItem {
    return item?.isLink === true;
  }

  const renderNavItems = (items: TNavigationItem[]) => {
    return items.map((item: TNavigationItem, i: number) => {
      if (isLinkItem(item)) {
        // TypeScript now knows item is LinkItem
        return renderLink(item, i);
      } else {
        // item is NonLinkItem
        return (
          <div key={`MainNav${i}`} className={`${s.nav_group}${activeLink ? s.active : ""}`}>
            <h5>{t(item.name).toUpperCase()}</h5>
            {item?.children?.map((childItem, j) => {
              if (isLinkItem(childItem)) {
                return renderLink(childItem, j);
              } else {
                // Handle nested groups if necessary
                return null;
              }
            })}
          </div>
        );
      }
    });
  };
  const splitLocation = location.pathname.split("/")[1];
  console.log(splitLocation, "splitLocation");
  const renderFolderStructure = (returnNavProp: string) => {
    return (
      <>
        {/* Return to dashboard */}
        <div className={s.nav_item} onClick={closeFolderStructureHandler}>
          <Link to={`/${returnNavProp}`}>
            <ChevronLeftIcon />
            {t("ReturnToDashboard")}
          </Link>
        </div>

        <NavFolderTreeStructure
          requestPath={selectedNavLinkRouterPath}
          setSelectedFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
          header={`${splitLocation.charAt(0).toUpperCase() + splitLocation.slice(1)} Folders`}
          dndType={splitLocation}
        />
        {/* Folder structure items */}
      </>
    );
  };

  const direction = openFolderStructure ? 1 : -1; // 1 for forward, -1 for backward

  return (
    <div className={`${s.container} ${openNav ? "" : s.hide}`}>
      {/* Dashboard at the top */}
      <div className={`${s.nav_item} ${activeLink === "Dashboard" ? s.active : ""}`}>
        <Link
          to="/dashboard"
          onClick={() => {
            closeFolderStructureHandler();
            setActiveLink("Dashboard");
          }}
        >
          <DashboardIcon className={s.icon} fontSize="medium" />
          {t("Dashboard")}
        </Link>
      </div>
      <AnimatePresence initial={false} custom={direction}>
        {!openFolderStructure && (
          <motion.div
            key="navItems"
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
          >
            {renderNavItems(navigationMenu(designStudioLink))}
          </motion.div>
        )}
        {openFolderStructure && (
          <motion.div
            key="folderStructure"
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
          >
            {renderFolderStructure(selectedNavLink.toLowerCase())}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MainNav;
