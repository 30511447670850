const saveCanvasJSON = (canvas: any, productName?: string) => {
  if (canvas) {
    const canvasJson = canvas.toJSON([
      "id",
      "name",
      "type",
      "opacity",
      "level",
      "selectable",
      "lockMovementX",
      "lockMovementY",
      "lockScalingX",
      "lockScalingY",
      "lockRotation",
      "hasControls",
      "uuid",
      "order",
    ]);

    const filteredObjects = canvasJson?.objects?.filter((el: any) => !el?.id?.endsWith("-clone"));
    console.log(filteredObjects, "filtered");

    const transformedJson = {
      product_name: productName,
      size: "some",
      pages: [
        {
          version: canvasJson.version,

        img_preview: "",
          name: productName,
          objects: canvasJson.objects,
        },
      ],
    };
    // console.log(transformedJson);
    return transformedJson;
  }
};
export default saveCanvasJSON;
