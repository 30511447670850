import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import s from "./Back.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
interface IBack {
  params: string | number;
}
const Back = ({ params }: IBack) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = () => {
    if (typeof params === "string") {
      navigate(params);
    } else if (typeof params === "number") {
      navigate(params);
    }
  };

  return (
    <div className={s.container} onClick={() => handleNavigation()}>
      <ArrowBackIosIcon fontSize="small" />
      <span>{t("Go back")}</span>
    </div>
  );
};

export default Back;
