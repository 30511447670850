import { useState, useCallback } from "react";
import apiClient from "./axios-client";
import { useNotification, uniqueID } from "../context/notifications/NotificationProvider";

export const usePostRequest = () => {
  const notification = useNotification();
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const postData = useCallback(async (path: string, postData: any) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiClient.post(path, postData);
      setData(response);
      return response;
    } catch (err: any) {
      setError(err.response ? err.response.data : err.message);
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: "Wystąpił nieoczekiwany bład. Próbuj ponownie pozniej!",
      });

      return err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { data, isLoading, error, postData };
};
