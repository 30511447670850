import React from "react";
import s from "./ConvertToVectorModal.module.scss";
import ModalHeader from "../ModalHeader";
import { useTranslation } from "react-i18next";
import Button from "../../Button/Button";

const ConvertToVectorModal = ({ handleOptionSelect }: any) => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <ModalHeader header={t("ConvertToVectorModal.Header")} />

      <div className={s.button_container}>
        <Button text={t("No")} type="button" handleClick={() => handleOptionSelect("No")} />
        <Button text={t("Yes")} type="button" handleClick={() => handleOptionSelect("Yes")} />
      </div>
    </div>
  );
};

export default ConvertToVectorModal;
