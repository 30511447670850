import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import s from "./Modal.module.scss";
import AppContext from "../../../core/context/AppContext";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
interface IBasicModal {
  children: React.ReactNode;
  open: boolean;
  setOpen: any;
}
const NormalModal = ({ children, open, setOpen }: IBasicModal) => {
  const { setStyleIsActive } = React.useContext(FabricJSContextNew);
  const handleClose = () => {
    setStyleIsActive(null);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={s.container}>{children}</div>
    </Modal>
  );
};
export default NormalModal;
