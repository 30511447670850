const sortCanvasObjectsByLevel = (canvas: fabric.Canvas) => {
  if (canvas) {
    const objectsWithLevel = canvas.getObjects();

    // Sort the objects by level, ensuring clones are behind their originals
    objectsWithLevel.sort((a, b) => {
      const levelA = typeof a.level === "number" ? a.level : Number.MAX_VALUE;
      const levelB = typeof b.level === "number" ? b.level : Number.MAX_VALUE;

      // Normal sorting by level for other objects
      return levelA - levelB;
    });
    // Explicitly set the stacking order based on the sorted array
    objectsWithLevel.forEach((obj, index) => {
      canvas.moveTo(obj, index);
    });
    canvas.requestRenderAll();
  }
};

export default sortCanvasObjectsByLevel;
