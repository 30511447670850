import { fabric } from "fabric";
import { serializeObject } from "./serializeObject";

const filters: any = {
  brightness: new fabric.Image.filters.Brightness(),
  saturation: new fabric.Image.filters.Saturation(),
  contrast: new fabric.Image.filters.Contrast(),
  hue: new fabric.Image.filters.HueRotation(),
  hueRotation: new fabric.Image.filters.HueRotation(),
  blur: new fabric.Image.filters.Blur(),
  grayscale: new fabric.Image.filters.Grayscale(),
  some: new fabric.Image.filters.BaseFilter(),
  vibrance: new fabric.Image.filters.Vibrance(),

  // tint: new fabric.Image.filters.Tint(),
  // sepia2: new fabric.Image.filters.Sepia2(),
  sepia: new fabric.Image.filters.Sepia(),
  resize: new fabric.Image.filters.Resize(),
  // removeWhite: new fabric.Image.filters.RemoveWhite(),
  pixelate: new fabric.Image.filters.Pixelate(),
  noise: new fabric.Image.filters.Noise(),

  gamma: new fabric.Image.filters.Gamma(),
  sharpen: new fabric.Image.filters.Convolute({
    matrix: [0, -1, 0, -1, 5, -1, 0, -1, 0],
  }),
  emboss: new fabric.Image.filters.Convolute({
    matrix: [1, 1, 1, 1, 0.7, -1, -1, -1, -1],
  }),
  changeColor: new fabric.Image.filters.BlendColor(),

  // tint: new fabric.Image.filters.Tint(),
};
const changeImg = (canvas: any, newImgProp: any, setCanvasActiveObject: any) => {
  const activeObject = canvas.getActiveObject();

  if (activeObject && activeObject.type === "image") {
    console.log("New Image Properties:", newImgProp);

    let filtersChanged = false;

    Object.entries(newImgProp).forEach(([filterType, filterConfig]) => {
      // Flatten the filterConfig to handle both key-value and object-based structures
      const flatFilterConfig =
        typeof filterConfig === "object" && filterConfig !== null
          ? filterConfig
          : { [filterType]: filterConfig };

      // Check if the filter is already applied
      const existingFilterIndex = activeObject.filters.findIndex(
        (filter: any) => filter.type === filters[filterType].type
      );

      if (existingFilterIndex !== -1) {
        // Update the existing filter
        console.log(`Updating existing filter: ${filterType}`);
        Object.assign(activeObject.filters[existingFilterIndex], flatFilterConfig);
      } else if (filters[filterType]) {
        // Add the filter if it doesn't exist
        console.log(`Adding new filter: ${filterType}`);
        const newFilter = new filters[filterType].constructor();
        Object.assign(newFilter, flatFilterConfig);

        activeObject.filters.push(newFilter);
      } else {
        console.warn(`Unknown filter type: ${filterType}`);
        return;
      }

      filtersChanged = true; // Mark filtersChanged as true
    });

    // Apply filters if any changes occurred
    if (filtersChanged) {
      activeObject.applyFilters();
    }

    // Serialize Active Object
    const sterilizedActiveObject = serializeObject(activeObject);

    setCanvasActiveObject((prev: any) => ({
      ...prev,
      objectJSON: sterilizedActiveObject,
    }));

    canvas.renderAll();
  } else {
    console.log("No active image object to modify.");
  }
};

export default changeImg;
