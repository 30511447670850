import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Layout from "../../layouts/Layout";
import Dashboard from "../../features/Dashboard/Dashboard";
import Settings from "../../features/Settings/Settings";
import Subscriptions from "../../features/Subscribtions/Subscriptions";

import PaymentWrapper from "../../features/Subscribtions/Managment/components/PaymentWrapper";
import Products from "../../features/ProductsDashboard/ProductsDashboard";
import Projects from "../../features/ProjectsDashboard/ProjectsDashboard";
import Media from "../../features/GraphicsDashboard/GraphicsDashboard";
import Text from "../../features/TextDashboard/TextDashboard";
import Bin from "../../features/Bin/Bin";
import LoginRoute from "./LoginRoute";
import Register from "../../features/Auth/Register/Register";
import OnboardProfile from "../../features/Auth/OnboardProfile/OnboardProfile";
import OnboardPaymentPlan from "../../features/Auth/OnboardPaymentPlan/OnboardPaymentPlan";
import OnboardWorkspace from "../../features/Auth/OnboardWorkspace/OnboardWorkspace";
import { useTranslation } from "react-i18next";
import RecoverPassword from "../../features/Auth/RecoverPassword/RecoverPassword";
import ChangePassword from "../../features/Auth/ChangePassword/ChangePassword";
import Activate from "../../features/Auth/Activate/Activate";
import PreviewStudio from "../../features/Fabric-JS/Preview-Studio/PreviewStudio";
import DesignStudio from "../../features/Fabric-JS/Design-Studio/DesignStudio";
import LoadingState from "../../components/LoadingState/LoadingState";
import FreeStudio from "../../features/Fabric-JS/FreeEditor/FreeStudio";

const RouterComponent = () => {
  const { t } = useTranslation();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },
    {
      path: "/free-editor/*",
      element: <FreeStudio />,
    },
    {
      path: "/design-studio/*",
      handle: {
        crumb: t("Design Studio"),
      },
      element: <DesignStudio />,
    },
    {
      path: "/s/2d/:token",
      handle: {
        crumb: t("Design Studio"),
      },
      element: <PreviewStudio />,
    },

    {
      path: "/dashboard",
      handle: {
        crumb: t("Dashboard"),
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <Dashboard />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/products",
      handle: {
        crumb: t("Products"),
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <Products />
          </Layout>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "folder/:folderToken",
          element: (
            <ProtectedRoute>
              <Layout>
                <Products />
              </Layout>
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/projects",
      handle: {
        crumb: t("Projects"),
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <Projects />
          </Layout>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "folder/:folderToken",
          element: (
            <ProtectedRoute>
              <Layout>
                <Products />
              </Layout>
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/media",
      handle: {
        crumb: t("Media"),
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <Media />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/text",
      handle: {
        crumb: t("Text"),
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <Text />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/bin",
      handle: {
        crumb: t("Bin"),
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <Bin />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/settings",
      handle: {
        crumb: t("Settings"),
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <Settings />
          </Layout>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "profile",
          handle: {
            crumb: t("Profile"),
          },
          element: <Settings />,
        },
        {
          path: "workspace",
          handle: {
            crumb: t("Work area"),
          },
          element: <Settings />,
        },
        {
          path: "personalization",
          handle: {
            crumb: t("Personalisation"),
          },
          element: <Settings />,
        },
        {
          path: "domain",
          handle: {
            crumb: t("Domain"),
          },
          element: <Settings />,
        },
      ],
    },
    {
      path: "/subscription",
      handle: {
        crumb: t("Subscription"),
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <Subscriptions />
          </Layout>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "managment",
          handle: {
            crumb: t("ManagmentComponent.Manage subscription"),
          },
          element: <Subscriptions />,
        },
        {
          path: "history",
          handle: {
            crumb: t("HistoryComponent.History order"),
          },
          element: <Subscriptions />,
        },
        {
          path: "payment",
          handle: {
            crumb: t("SubscritpionNavigation.two"),
          },
          element: <Subscriptions />,
        },
      ],
    },
    {
      path: "subscription/change-subscription",
      handle: {
        crumb: ["Subskrypcja", "Zarządzaj Subskrypcja", "Zmień plan"],
      },
      element: (
        <ProtectedRoute>
          <Layout>
            <PaymentWrapper />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "login",

      element: <LoginRoute />,
    },
    {
      path: "register",

      element: <Register />,
    },
    {
      path: "activation/:token",

      element: <Activate />,
    },
    {
      path: "recover-password",

      element: <RecoverPassword />,
    },
    {
      path: "password/reset/:token",

      element: <ChangePassword />,
    },

    {
      path: "onboard/payment-plan",

      element: <OnboardPaymentPlan />,
    },

    {
      path: "onboard/profile-setup",

      element: <OnboardProfile />,
    },

    {
      path: "onboard/workspace",

      element: <OnboardWorkspace />,
    },
    {
      path: "*",
      element: <LoadingState />,
    },
  ]);
  return <RouterProvider router={router} />;
};
export default RouterComponent;
