import { fabric } from "fabric";
import createFollowerImage from "../AddToCanvas/createFollowers/CreateFollowerImage";
import updateClipPath from "../UpdateClipPath";
import { extendObject } from "../ExtendObject";
import sortCanvasObjectsByLevel from "../SortCanvasObjectsByLevel";
import { updateCanvasObjectsList } from "../UpdateCanvasObjectList";

const duplicateCanvasObject = (
  canvas: fabric.Canvas,
  objectId: string,
  setCanvasObjectList: any,
  level: number
): void => {
  const objectToDuplicate = canvas.getObjects().find((obj) => obj.id === objectId);

  if (
    objectToDuplicate &&
    (objectToDuplicate.type === "image" || objectToDuplicate.type === "i-image")
  ) {
    objectToDuplicate.clone(async (newObject: fabric.Object) => {
      // Set position of the duplicate object to the top-left corner of the canvas
      newObject.set({
        left: 0,
        top: 0,
      });

      // Set properties for the new object (clone) image
      newObject.id = `${Date.now().toString()}-img`;
      newObject.name = `${objectToDuplicate.name}`;
      newObject.level = level;

      if (newObject.level === 4) {
        const cloneSettings = {
          id: `${newObject.id}-clone`,
          name: `${newObject.name}-clone`,
          level: 3,
        };
        const cloneOfNewObject = await createFollowerImage(objectToDuplicate, cloneSettings);
        cloneOfNewObject.set({ left: 0, top: 0 });

        canvas.add(cloneOfNewObject);
      }

      canvas.add(newObject);
      sortCanvasObjectsByLevel(canvas);
      updateClipPath(canvas, newObject);
      updateCanvasObjectsList(canvas, setCanvasObjectList);
      canvas.renderAll();
    });
  } else if (
    (objectToDuplicate && objectToDuplicate.type === "i-text") ||
    (objectToDuplicate && objectToDuplicate.type === "text")
  ) {
    objectToDuplicate.clone(async (newText: fabric.Object) => {
      newText.set({
        left: 0,
        top: 0,
      });
      const extendedNewText = extendObject(newText);
      extendedNewText.id = `${Date.now().toString()}-text`;
      extendedNewText.level = level;

      if (extendedNewText.level === 4) {
        const cloneSettings = {
          id: `${newText.id}-clone`,
          level: 3,
        };

        const cloneOfNewText = await createFollowerImage(objectToDuplicate, cloneSettings);
        cloneOfNewText.set({ left: 0, top: 0 });

        canvas.add(cloneOfNewText);
      }

      canvas.add(extendedNewText);
      sortCanvasObjectsByLevel(canvas);
      updateCanvasObjectsList(canvas, setCanvasObjectList);
      canvas.renderAll();
    });
  }
};

export default duplicateCanvasObject;
