import { fabric } from "fabric";

interface CloneSettings {
  id: string;
  name: string;
  level: number;
}

const createFollowerObject = (
  obj: fabric.Object,
  cloneSettings: CloneSettings
): Promise<fabric.Object> => {
  return new Promise((resolve) => {
    if (typeof obj.clone === "function") {
      obj.clone((clonedObj: fabric.Object) => {
        clonedObj.set({
          ...cloneSettings,
          opacity: 0.5,
          selectable: true,
          evented: false,
          clipPath: undefined,
        });
        resolve(clonedObj);
      });
    }
  });
};

export default createFollowerObject;
