import { useTranslation } from "react-i18next";
import s from "./History.module.scss";
const dd = [
  {
    data: "2019-12-06",
    product: "Videlink - Konto Free",
    orderNumber: "VDL 123",
    amount: "29,00$",
    receipt: "N/A",
  },
  {
    data: "2019-12-06",
    product: "Videlink - Konto Free",
    orderNumber: "VDL 123",
    amount: "29,00$",
    receipt: "N/A",
  },
];
const History = () => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t("HistoryComponent.History order")}</h3>
      <div className={s.container}>
        <table>
          <thead>
            <tr>
              <td>{t("HistoryComponent.Date")}</td>
              <td>{t("HistoryComponent.Product")}</td>
              <td>{t("HistoryComponent.Date")}</td>
              <td>{t("HistoryComponent.Amount")}</td>
              <td>{t("HistoryComponent.PDF")}</td>
            </tr>
          </thead>
          <tbody>
            {dd.map((el) => (
              <tr>
                <td>{el.data}</td>
                <td>{el.product}</td>
                <td>{el.orderNumber}</td>
                <td>{el.amount}</td>
                <td>{el.receipt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default History;
