import React, { useContext, useEffect, useState } from "react";
import s from "../WorkShopProduct.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import ImageIcon from "@mui/icons-material/Image";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import duplicateCanvasObject from "../../../../../fabric-functions/ManipulateObjects/DuplicateObject";
import { reorderCanvasObjects } from "../../../../../fabric-functions/UpdateCanvasObjectList";
import changeObjectName from "../../../../../fabric-functions/ManipulateObjects/ChangeObjectName";
import hideObject from "../../../../../fabric-functions/ManipulateObjects/HideObject";
import lockObject from "../../../../../fabric-functions/LockObject";
import deleteObject from "../../../../../fabric-functions/ManipulateObjects/DeleteObject";

const RenderLayers = () => {
  const { canvasContext, canvasObjectList, setCanvasObjectList }: any =
    useContext(FabricJSContextNew);

  const [columns, setColumns] = useState<any>({
    bottomLayers: {
      id: "bottomLayers",
      items: canvasObjectList.filter((obj: any) => obj.level === 1),
    },
    topLayers: {
      id: "topLayers",
      items: canvasObjectList.filter((obj: any) => obj.level === 5),
    },
  });

  // Update columns when canvasObjectList changes
  useEffect(() => {
    setColumns({
      bottomLayers: {
        id: "bottomLayers",
        items: canvasObjectList.filter((obj: any) => obj.level === 1),
      },
      topLayers: {
        id: "topLayers",
        items: canvasObjectList.filter((obj: any) => obj.level === 5),
      },
    });
  }, [canvasObjectList]);

  // Remove useEffect to prevent resetting columns during drag

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceItems = [...sourceColumn.items];
    const destItems =
      source.droppableId === destination.droppableId ? sourceItems : [...destColumn.items];

    const [removed] = sourceItems.splice(source.index, 1);

    if (source.droppableId !== destination.droppableId) {
      removed.level = destination.droppableId === "bottomLayers" ? 1 : 5;
    }

    destItems.splice(destination.index, 0, removed);

    const newColumns = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    };

    // Update 'order' property
    const updateOrder = (list: any) => {
      list.forEach((item: any, index: any) => {
        item.order = index;
      });
    };
    updateOrder(newColumns.bottomLayers.items);
    updateOrder(newColumns.topLayers.items);

    // Update the fabric.js objects
    if (canvasContext) {
      const allObjects = canvasContext.getObjects();

      // Update levels and orders based on newColumns
      newColumns.bottomLayers.items.forEach((item: any) => {
        const obj = allObjects.find((o: any) => o.id === item.id);
        if (obj) {
          obj.level = 1;
          obj.order = item.order;
        }
      });

      newColumns.topLayers.items.forEach((item: any) => {
        const obj = allObjects.find((o: any) => o.id === item.id);
        if (obj) {
          obj.level = 5;
          obj.order = item.order;
        }
      });

      // Create updatedCanvasObjectList
      const updatedCanvasObjectList = allObjects;

      setCanvasObjectList(updatedCanvasObjectList);

      // Reorder canvas objects
      reorderCanvasObjects(canvasContext, updatedCanvasObjectList);
    }

    // Update the columns state
    setColumns(newColumns);
  };

  const handleObjectNameChange = (e: any, objectId: string) => {
    changeObjectName(canvasContext, objectId, e.target.value, setCanvasObjectList);
  };

  const handleToggleHideObject = (objectId: string) => {
    hideObject(canvasContext, objectId, setCanvasObjectList);
  };

  const handleToggleLockObjectById = (objectId: string) => {
    lockObject(canvasContext, objectId, setCanvasObjectList);
  };

  const handleDeleteObject = (selectedObjId: string) => {
    deleteObject(canvasContext, selectedObjId, setCanvasObjectList);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {Object.entries(columns).map(([columnId, column]: any) => (
        <div key={columnId}>
          <h3>{columnId === "bottomLayers" ? "Bottom Layers" : "Top Layers"}</h3>
          <Droppable
            droppableId={columnId}
            isDropDisabled={false}
            isCombineEnabled={false}
            ignoreContainerClipping={false}
          >
            {(provided: any) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {column.items.map((el: any, index: number) => (
                  <Draggable key={el.id} draggableId={el.id} index={index}>
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                        className={s.layer_container}
                      >
                        {el?.id?.includes("-img") ? <ImageIcon /> : <FormatBoldIcon />}
                        <input
                          type="text"
                          placeholder={el?.type.includes("text") ? el.text : el.name}
                          value={el?.type.includes("text") ? el.text : el.name}
                          onChange={(e) => handleObjectNameChange(e, el.id)}
                        />

                        <div className={s.icons}>
                          <Tooltip placement="top" title="Hide">
                            <VisibilityIcon onClick={() => handleToggleHideObject(el.id)} />
                          </Tooltip>
                          <Tooltip placement="top" title="Duplicate">
                            <ContentCopyIcon
                              onClick={() =>
                                duplicateCanvasObject(
                                  canvasContext,
                                  el.id,
                                  setCanvasObjectList,
                                  el.level
                                )
                              }
                            />
                          </Tooltip>
                          {el.hasControls ? (
                            <Tooltip placement="top" title="Lock">
                              <LockIcon onClick={() => handleToggleLockObjectById(el.id)} />
                            </Tooltip>
                          ) : (
                            <Tooltip placement="top" title="Unlock">
                              <LockOpenIcon onClick={() => handleToggleLockObjectById(el.id)} />
                            </Tooltip>
                          )}
                          <Tooltip placement="top" title="Delete">
                            <DeleteIcon
                              className="bin-icon-mui"
                              onClick={() => handleDeleteObject(el.id)}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ))}
    </DragDropContext>
  );
};

export default RenderLayers;
