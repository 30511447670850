import React, { useContext, useState } from "react";
import s from "./Login.module.scss";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AuthContext from "../../../core/context/AuthContext";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";
import { isEmptyString, validateEmail } from "../../../helper/validation";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { baseDomain } from "../../../core/utils";

const client_id = process.env.REACT_APP_OAUTH2_CLIENT_ID;
const client_secret = process.env.REACT_APP_OAUTH2_CLIENT_SECRET;
const grant_type = process.env.REACT_APP_OAUTH2_GRANT_TYPE;

const Login = () => {
  const [input, setInput] = useState({
    client_id,
    client_secret,
    grant_type,
    username: "",
    password: "",
  });

  const { setUser } = useContext(AuthContext);
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [passwordNotValid, setPasswordNotValid] = useState<boolean>(false);
  const { sendRequest, error, isLoading } = useApiRequest();

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
    });

    if (name === "username") {
      setIsEmailValid(validateEmail(value));
    } else if (name === "password") {
      setPasswordNotValid(isEmptyString(value));
    }
  };

  const handleLogin = async () => {
    if (!isEmailValid || passwordNotValid) return;

    const response: any = await sendRequest("post", "/oauth/token", input);
    if (response) {
      console.log(response);

      const { access_token, refresh_token } = response;
      Cookies.set("vl_access_token", access_token, {
        domain: `.${baseDomain}`,
        path: "/",
      });
      Cookies.set("vl_refresh_token", refresh_token, {
        domain: `.${baseDomain}`,
        path: "/",
      });

      try {
        const responseUser: any = await sendRequest("get", "/user");
        console.log(responseUser);
        setUser(responseUser?.data);
        console.log(responseUser, "Login Component response");
        // navigate("/design-studio");
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const isButtonDisabled =
    !isEmailValid ||
    passwordNotValid ||
    isEmptyString(input.username) ||
    isEmptyString(input.password);

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <h2>VIDELINK</h2>
          <span>{t("LoginComponent.Login")}</span>
          <TextField
            name="username"
            onChange={inputHandler}
            className={s.input}
            id="outlined-basic"
            autoComplete="off"
            label={t("Address email")}
            variant="outlined"
            color="primary"
            error={!isEmailValid && !isEmptyString(input.username)}
            onBlur={() => setIsEmailValid(validateEmail(input.username))}
          />

          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            autoComplete="off"
            onChange={inputHandler}
            className={s.input}
            id="outlined-basic"
            label={t("Password")}
            variant="outlined"
            value={input.password}
            onBlur={() => setPasswordNotValid(isEmptyString(input.password))}
            error={passwordNotValid && !isEmptyString(input.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className={s.half_container}>
            <Button
              text={isLoading ? t("Loading") : t("Login")}
              handleClick={() => handleLogin()}
              type="button"
              isLoading={isLoading}
              disabled={isButtonDisabled}
            />

            <Link to="/recover-password">{t("LoginComponent.Forgot password")}</Link>
            <Link to="/free-editor">{"Free editor"}</Link>
          </div>
          <p>{t("LoginComponent.one")}</p>
          <Link to="/register">{t("LoginComponent.two")}</Link>
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default Login;
