import { uuidv4 } from "../../../helper/uuid";

export const extendObject = (obj: any) => {
  obj.set({
    padding: 25,
    cornerColor: "#60a1ff",
    borderColor: "#627485",
    cornerSize: 13,
    hasControls: true,
    hasBorders: true,
    transparentCorners: false,
    cornerStyle: "circle",
    lockScalingFlip: true,
  });
  return obj;
};

export const extendSVGObject = (svg: fabric.Object, imageInfo: any): fabric.Object => {
  const extendedSVG = svg as fabric.Object & {
    id: string;
    name: string;
    level: number | undefined;
    uuid: string;
    order: number;
  };

  extendedSVG.id = `${Date.now().toString()}-svg`;
  extendedSVG.name = imageInfo.name || "no-name";
  extendedSVG.level = imageInfo.level;
  extendedSVG.opacity = 1; // Set default opacity or use imageInfo to set a custom one
  extendedSVG.order = imageInfo.order;
  const uuid = uuidv4();
  extendedSVG.uuid = uuid;

  return extendObject(extendedSVG); // Apply additional extensions if needed
};
export const extendImageObject = (img: fabric.Image, imageInfo: any) => {
  const extendedImg = img as fabric.Image & {
    id: string;
    name: string;
    level: number | null;
    uuid: string;
    order: number;
  };

  extendedImg.id = `${Date.now().toString()}-img`;
  extendedImg.name = imageInfo.name || "no-name";
  extendedImg.level = imageInfo.level;
  extendedImg.order = imageInfo.order;
  extendedImg.opacity = 1;

  const uuid = uuidv4();
  extendedImg.uuid = uuid;

  return extendObject(extendedImg);
};

export const extendTextInfo = (text: fabric.IText, textInfo: any) => {
  const extendedText = text as unknown as fabric.Text & {
    id: string;
    name: string;
    level: number | null;
    uuid: string;
    order: number;
  };
  console.log(textInfo, "EXTENDED TEXT INFOS");

  extendedText.id = `${Date.now().toString()}-text`;
  extendedText.name = textInfo.name || "no-name";
  extendedText.level = textInfo.level;
  extendedText.order = textInfo.order;
  extendedText.opacity = 1;

  const uuid = uuidv4();
  extendedText.uuid = uuid;

  return extendObject(extendedText);
};
