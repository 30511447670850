import axios from "axios";
import Cookies from "js-cookie";
import { apiEndpoint } from "../utils";

const apiClient = axios.create({
  baseURL: apiEndpoint,
});

apiClient.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("vl_access_token");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
