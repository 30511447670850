import React, { useContext, useState } from "react";
import s from "./AddViewButton.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { useApiRequest } from "../../core/api/useApiRequest";
import FabricJSContextNew from "../../core/context/FabricJSContextNew";
import { uuidv4 } from "../../helper/uuid";
import { filterViewToShow } from "../ProductComponent/filterViewToShow";
import NormalModal from "../Modals/Modal/NormalModal";
import ModalWrapper from "../Modals/Modal.wrapper";
import SelectProductModal from "../Modals/SelectProduct/SelectProductModal";
import ProductContext from "../../core/context/ProductContext";
import { uniqueID, useNotification } from "../../core/context/notifications/NotificationProvider";
import handleLoadCanvasProject from "../../features/Fabric-JS/fabric-functions/LoadProject";

const AddViewButton = () => {
  const { activeGlobalState, setActiveGlobalState, canvasContext } = useContext(FabricJSContextNew);
  const [openSelectVersion, setOpenSelectVersion] = useState<boolean>(false);

  const { sendRequest } = useApiRequest();
  const { setProducts } = useContext(ProductContext);

  const notification = useNotification();

  const createViewHandler = async () => {
    if (activeGlobalState.canvasType === "projects") {
      setOpenSelectVersion(true);
      return;
    }

    const response = await sendRequest(
      "post",
      `/creator/2d/templates/${activeGlobalState.fullJSON.id}/batch`,
      [
        {
          event: "view_create",
          payload: {
            uuid: uuidv4(),
          },
        },
      ]
    );

    if (response && response.data) {
      const responseData = response.data as any;
      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "View added",
      });
      setActiveGlobalState((prevState: any) => {
        const newView = responseData.views[responseData.views.length - 1];

        const newState = {
          ...prevState,
          fullJSON: {
            ...prevState.fullJSON,
            views: [...prevState.fullJSON.views, newView],
          },
          selectedView: newView,
          generateThumbnail: null,
        };

        const viewToShow = filterViewToShow(
          newView.uuid,
          newState.fullJSON,
          activeGlobalState.canvasType
        );
        // console.log(viewToShow, "THIS VIEW IS SHOIWNG");
        handleLoadCanvasProject(canvasContext, viewToShow.canvasJSON);

        return newState;
      });
      setProducts((prevItems: any) =>
        prevItems.map((item: any) => (item.id === responseData.id ? responseData : item))
      );
    }
  };

  return (
    <>
      <div className={s.container} onClick={() => createViewHandler()}>
        <AddIcon fontSize="large" />
      </div>
      <NormalModal open={openSelectVersion} setOpen={setOpenSelectVersion}>
        <ModalWrapper setOpen={setOpenSelectVersion}>
          <SelectProductModal setCloseModal={setOpenSelectVersion} />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default AddViewButton;
