import React, { useContext } from "react";
import s from "./ProjectList.module.scss";

import DashboardTopHeader from "../../../components/DashboardTopHeader/DashboardTopHeader";
import { useNavigate } from "react-router-dom";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { useTranslation } from "react-i18next";

import ProjectContext from "../../../core/context/ProjectContext";
import ProjectComponent from "../../../components/ProjectComponent.tsx/ProjectComponent";
import FilterComponent from "../../../components/FilterComponent/FilterComponent";
const ProjectList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveGlobalState } = useContext(FabricJSContextNew);

  const { projects } = useContext(ProjectContext);

  const projectHanlder = (selectedView: any, fullJSON: any) => {
    setActiveGlobalState({
      canvasType: "projects",
      fullJSON,
    });
    navigate(`/design-studio/project/${fullJSON?.token}`);
  };

  return (
    <div className={s.container}>
      <DashboardTopHeader header={t("Projects")} navigateTo={"/projects"} />
      <div className={s.list_container}>
        <FilterComponent
          endpoint="/creator/2d/projects"
          defaultSort="id"
          defaultPagination={5}
          isPagination={false}
          render={({ data, onDelete }) => (
            <div className={s.map_container}>
              {data?.map((el: any, i: number) => (
                <ProjectComponent
                  key={`dashboard-projects-projects-recent-map${i}`}
                  project={el}
                  variant="medium"
                  handleClick={(variant) => projectHanlder(variant, el)}
                  id={`dashboard-projects-projects-recent-map${i}`}
                  componentOptions="1"
                  onDelete={onDelete}
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ProjectList;
