import React, { useContext, useEffect, useState } from "react";
import s from "./TopToolsText.module.scss";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import { fonts } from "./fonts";
import { Autocomplete, TextField } from "@mui/material";
import IncreaseFont from "../../../../../../../components/IncreaseFont/IncreaseFont";
import { Wheel } from "@uiw/react-color";

import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import changeText from "../../../../../fabric-functions/ChangeText";

const TopToolsText = () => {
  const { canvasContext, canvasActiveObject, setCanvasActiveObject } =
    useContext(FabricJSContextNew);

  const [showColorPallete, setShowCollorPallete] = useState<boolean>(false);

  const colorHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    setShowCollorPallete(!showColorPallete);
  };

  const boldnessHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      const newFontWeight = activeObject.fontWeight === "normal" ? "bold" : "normal";
      changeText(canvasContext, { fontWeight: newFontWeight }, setCanvasActiveObject);
    }
  };

  const italicHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      const newFontStyle = activeObject.fontStyle === "normal" ? "italic" : "normal";
      changeText(canvasContext, { fontStyle: newFontStyle }, setCanvasActiveObject);
    }
  };

  const underlineHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      const newUnderline = !activeObject.underline;
      changeText(canvasContext, { underline: newUnderline }, setCanvasActiveObject);
    }
  };

  const alignHandler = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click bubbling
    const activeObject = canvasActiveObject?.objectJSON;
    if (activeObject) {
      const newTextAlign = activeObject.textAlign === "left" ? "center" : "left";
      changeText(canvasContext, { textAlign: newTextAlign }, setCanvasActiveObject);
    }
  };

  const fontStyleHandler = (value: string | null) => {
    const activeObject = canvasActiveObject?.objectJSON;

    if (activeObject && value) {
      console.log(activeObject, "activeObject");
      changeText(canvasContext, { fontFamily: value }, setCanvasActiveObject);
    }
  };

  const fillColorHandler = (value: string | null) => {
    if (value) {
      changeText(canvasContext, { fill: value }, setCanvasActiveObject);
    }
  };
  console.log(canvasActiveObject);
  const [hex, setHex] = useState(canvasActiveObject?.objectJSON?.fill || "#FF0000");
  useEffect(() => {
    if (canvasActiveObject?.objectJSON?.fill) {
      const fillColor =
        canvasActiveObject.objectJSON.fill === "#000000"
          ? "#FF0000"
          : canvasActiveObject.objectJSON.fill;
      setHex(fillColor);
    }
  }, [canvasActiveObject]);
  return (
    <div className={s.container}>
      <div className={s.color_pallet_container} onClick={colorHandler}>
        <div
          className={s.current_color}
          style={{
            background: canvasActiveObject?.objectJSON ? canvasActiveObject?.objectJSON?.fill : hex,
          }}
        ></div>
        {showColorPallete ? (
          <div className={s.pallet}>
            <Wheel
              className={s.pallet}
              style={{ marginLeft: 20 }}
              color={hex}
              onChange={(color) => {
                setHex(color.hex);
                fillColorHandler(color.hex);
              }}
            />
          </div>
        ) : null}
      </div>
      <Autocomplete
        className={s.autocomplete}
        disablePortal
        id="combo-box-demo"
        options={fonts}
        getOptionLabel={(option) => option.label} // Use the label as display value
        onChange={(event, newValue) => {
          if (newValue) {
            fontStyleHandler(newValue.key); // Pass the font key to your handler
          }
        }}
        value={
          fonts.find((font) => font.key === canvasActiveObject?.fontFamily) ||
          fonts.find((font) => font.label === "Times New Roman")
        }
        size="small"
        renderInput={(params) => <TextField {...params} sx={{ borderRadius: "4px" }} />}
      />

      <IncreaseFont fontSize={canvasActiveObject?.fontSize || "14"} />

      <div className={s.icon_container}>
        <FormatBoldIcon fontSize="medium" onClick={boldnessHandler} />
      </div>

      <div className={s.icon_container}>
        <FormatItalicIcon fontSize="medium" onClick={italicHandler} />
      </div>
      <div className={s.icon_container}>
        <FormatUnderlinedIcon fontSize="medium" onClick={underlineHandler} />
      </div>

      <div className={s.icon_container}>
        <FormatAlignCenterIcon fontSize="medium" onClick={alignHandler} />
      </div>
    </div>
  );
};

export default TopToolsText;
