import React from "react";
import { LeftBarImageI } from "../../../../types/generalTypes";

const Graphics = ({ isActive, isLoggedIn }: LeftBarImageI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      width="30.963"
      height="26.5"
      viewBox="0 0 30.963 26.5"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_4346"
            data-name="Path 4346"
            d="M707.738,324H738.7v26.5H707.738Zm0,0"
            transform="translate(-707.738 -324)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="In_Post_Images_1_" data-name="In Post Images (1)" clipPath="url(#clip-path)">
        <path
          id="Path_4345"
          data-name="Path 4345"
          d="M711.31,324A3.325,3.325,0,0,0,708,327.313v19.875a3.325,3.325,0,0,0,3.314,3.313h24.3a3.325,3.325,0,0,0,3.314-3.313V327.313A3.325,3.325,0,0,0,735.613,324Zm0,2.208h24.3a1.09,1.09,0,0,1,1.1,1.1v16.045l-7.741-8.169-4.315,4.857-6.654-7.013-7.8,8.782v-14.5A1.09,1.09,0,0,1,711.31,326.208Zm18.779,2.208a2.208,2.208,0,1,0,2.21,2.208A2.213,2.213,0,0,0,730.089,328.417Zm-12.031,7.876,11.383,12H711.31a1.09,1.09,0,0,1-1.1-1.1v-2.044Zm10.961,2.165,7.7,8.109v.621a1.09,1.09,0,0,1-1.1,1.1H732.48l-6.291-6.642Zm0,0"
          transform="translate(-707.98 -324)"
          fill={!isActive ? "#ffff" : isLoggedIn ? "#808d98" : "#808d98"}
        />
      </g>
    </svg>
  );
};

export default Graphics;
