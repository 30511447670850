import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Login from "../../features/Auth/Login/Login";
import LoadingState from "../../components/LoadingState/LoadingState";
import { loadingStyle100 } from "../../helper/customeStyles";
import { useTranslation } from "react-i18next";

const LoginRoute = () => {
  const { user, isLoading } = useContext(AuthContext);
  const { t } = useTranslation();
  const userIsPresentPath = "/dashboard";

  // Show loading spinner while determining user status
  if (isLoading) {
    return <LoadingState styles={loadingStyle100} text={t("Loading")} />;
  }

  // If user is authenticated, navigate to dashboard
  return user ? <Navigate to={userIsPresentPath} replace /> : <Login />;
};

export default LoginRoute;
