import { useState, useCallback } from "react";
import apiClient from "./axios-client";

export const useGetRequest = () => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const getData = useCallback(async (path: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiClient.get(path);
      setData(response.data);
      return response;
    } catch (err: any) {
      setError(err.response ? err.response.data : err.message);

      return err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { data, isLoading, error, getData };
};
