// NavFolderTreeStructure.tsx
import React, { useState, useEffect, useContext } from "react";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { Droppable } from "react-beautiful-dnd";
import { useApiRequest } from "../../../core/api/useApiRequest";
import AppContext from "../../../core/context/AppContext";
import FolderIcon from "@mui/icons-material/Folder";
import s from "./NavFolderTreeStructure.module.scss";
interface IFolder {
  id: number;
  name: string;
  parent_id: number;
  children?: [];
}

interface INavFolderTreeStructure {
  requestPath: string;
  setSelectedFolder?: any;
  selectedFolder?: any;
  header: string;
  dndType: string;
}

const NavFolderTreeStructure = ({
  requestPath,
  setSelectedFolder,
  selectedFolder,
  header,
  dndType,
}: INavFolderTreeStructure) => {
  const { sendRequest } = useApiRequest();
  const { forceFetchFolder } = useContext(AppContext);
  const [treeData, setTreeData] = useState<TreeViewBaseItem[]>([]);

  const fetchFolders = async () => {
    try {
      const response: any = await sendRequest("get", `creator/2d/${requestPath}/categories`);
      const data: IFolder[] = response.data;

      const treeItems = buildTreeData(data);
      setTreeData(treeItems);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  useEffect(() => {
    fetchFolders();
  }, [forceFetchFolder]);

  const buildTreeData = (items: IFolder[]): TreeViewBaseItem[] => {
    const idToNodeMap: { [key: number]: TreeViewBaseItem } = {};

    items.forEach((item) => {
      idToNodeMap[item.id] = {
        id: item.id.toString(),
        label: item.name,
        children: [],
      };
    });

    const roots: TreeViewBaseItem[] = [];

    items.forEach((item) => {
      const node = idToNodeMap[item.id];
      if (item.parent_id === 0) {
        roots.push(node);
      } else {
        const parent = idToNodeMap[item.parent_id];
        if (parent) {
          parent.children!.push(node);
        }
      }
    });

    return roots;
  };

  return (
    <div className={s.outer_container}>
      <h4>{header}</h4>
      {treeData.map((node) => (
        <FolderNode key={node.id} node={node} />
      ))}
    </div>
  );
};

// Recursive folder node rendering with drop zones
const FolderNode = ({ node }: { node: TreeViewBaseItem }) => {
  return (
    <Droppable droppableId={`folder-${node.id}`} type="PRODUCTS">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps} className={s.container}>
          <div className={s.each_node}>
            <FolderIcon />
            <span>{node.label}</span>
          </div>
          {provided.placeholder}
          {node.children && node.children.length > 0 && (
            <div className={s.folder_children}>
              {node.children.map((child) => (
                <FolderNode key={child.id} node={child} />
              ))}
            </div>
          )}
        </div>
      )}
    </Droppable>
  );
};

export default NavFolderTreeStructure;
