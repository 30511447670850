import React, { useContext, useEffect, useState } from "react";
import s from "./FabricJSPreview.module.scss";
import FabricJSContextNew from "../../../../core/context/FabricJSContextNew";
import { fabric } from "fabric";

import { useApiRequest } from "../../../../core/api/useApiRequest";
import { useParams } from "react-router-dom";
import RightPanel from "./panels/RightPanel";
import LeftPanel from "./panels/LeftPanel";
import setupCanvasEventListeners from "../../fabric-functions/EventListiners/EventListiners";
import sortCanvasObjectsByLevel from "../../fabric-functions/SortCanvasObjectsByLevel";
import { centerCanvasAtOrigin } from "../../fabric-functions/LoadProject";
import { updateCanvasObjectsList } from "../../fabric-functions/UpdateCanvasObjectList";

const FabricJSPreview = () => {
  const {
    canvasContext,
    canvasRefs,
    canvasContainerRefs,
    setCanvasContext,
    setCanvasObjectList,
    setCanvasJSONContext,
    setCanvasSize,
    canvasSize,
    setCanvasActiveObject,
    setActiveGlobalState,
  } = useContext(FabricJSContextNew);

  useEffect(() => {
    if (canvasContainerRefs[1].current) {
      if (!canvasContext) {
        const newCanvas = new fabric.Canvas(canvasRefs[1].current);
        setCanvasContext(newCanvas);
        setCanvasJSONContext(newCanvas.toJSON());
      } else {
        // Directly set the lower and upper canvas inside the container
        if (canvasContainerRefs[1]) {
          canvasContainerRefs[1].current.innerHTML = "";
          canvasContainerRefs[1].current.appendChild(canvasContext.lowerCanvasEl);
          canvasContainerRefs[1].current.appendChild(canvasContext.upperCanvasEl);

          canvasContext.renderAll();
        }
      }
    }
  }, [canvasContext, setCanvasContext, canvasContainerRefs[1]]);

  useEffect(() => {
    if (canvasContext) {
      const cleanup = setupCanvasEventListeners(
        canvasContext,
        sortCanvasObjectsByLevel,
        setCanvasJSONContext,
        setCanvasObjectList,
        setActiveGlobalState,
        setCanvasActiveObject
      );

      centerCanvasAtOrigin(canvasContext);

      //keeps track of actual UI State render list
      updateCanvasObjectsList(canvasContext, setCanvasObjectList);

      return () => {
        cleanup();
      };
    }
  }, [canvasContext]);

  useEffect(() => {
    if (canvasContext) {
      canvasContext.setDimensions({
        width: canvasSize?.width - 1,
        height: canvasSize?.height - 1,
      });
    }
    centerCanvasAtOrigin(canvasContext);
  }, [canvasSize]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect) {
        setCanvasSize({
          width: entries[0].contentRect.width - 1,
          height: entries[0].contentRect.height - 1,
        });
      }
    });
    if (canvasContainerRefs[1].current) {
      resizeObserver.observe(canvasContainerRefs[1].current);
    }
    return () => {
      if (canvasContainerRefs[1].current) {
        resizeObserver.unobserve(canvasContainerRefs[1].current);
      }
    };
  }, [canvasContext, setCanvasSize]);

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 80px)",
        }}
      >
        <RightPanel />
        <LeftPanel />

        <div
          ref={canvasContainerRefs[1]}
          className={s.canvas_container}
          style={{
            width: "100%",
            display: "flex",
            height: "100%",
            position: "relative",
          }}
        >
          <canvas ref={canvasRefs[1]} id="preview-fabric-preview-studio" />
        </div>
      </div>
    </div>
  );
};

export default FabricJSPreview;
