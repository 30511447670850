import React from "react";
import s from "./DashboardTopHeader.module.scss";
import { Link } from "react-router-dom";
interface ITopHeader {
  header: string;
  navigateTo: string;
}

const DashboardTopHeader = ({ header, navigateTo }: ITopHeader) => {
  return (
    <div className={s.header}>
      <div>
        <h3>{header}</h3>

        <Link to={navigateTo}>Zobacz wszystkie</Link>
      </div>
      <div className="solid-line"></div>
    </div>
  );
};
export default DashboardTopHeader;
