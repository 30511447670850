import React, { useContext, useEffect, useState } from "react";
import s from "./LeftBar.module.scss";
import Products from "./svgs/Products";
import Projects from "./svgs/Projects";
import Graphics from "./svgs/Graphics";
import Text from "./svgs/Text";
import RenderAction from "./components/RenderAction";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../core/context/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import AppContext from "../../../core/context/AppContext";
import { useLocation } from "react-router-dom";

// Define a type for the function that generates the SVG component
type ImgGenerator = (isActive: boolean, isLoggedIn: boolean) => JSX.Element;

const LeftBar = () => {
  const [activeItem, setActiveItem] = useState<null | string>(null);

  const [action, setAction] = useState<string>("");
  const [slideDirection, setSlideDirection] = useState("");

  const { user } = useContext(AuthContext);

  const { openLeftBar, setOpenLeftBar } = useContext(AppContext);

  const { t } = useTranslation();

  const slidingAnimation = () => {
    if (slideDirection === "") {
      setSlideDirection("slide_right");
    } else {
      setSlideDirection((prev) => (prev === "slide_right" ? "slide_left" : "slide_right"));
    }

    setTimeout(() => {
      if (slideDirection !== "") {
        setSlideDirection("slide_right");
      }
    }, 150);
  };

  const handleItemClick = (itemName: string, actionName: string, isLoggedIn: boolean) => {
    if (isLoggedIn && !user) return;
    setActiveItem(itemName);

    if (action === actionName && openLeftBar) {
      setOpenLeftBar(false);
      setSlideDirection("slide_left");
      return;
    } else {
      setOpenLeftBar(true);
      setAction(actionName);
      slidingAnimation();
    }
  };

  useEffect(() => {
    let timer: any;
    if (!openLeftBar) {
      timer = setTimeout(() => {
        setActiveItem(null); // This will remove the active class after 2 seconds
      }, 2000);
    }

    return () => {
      clearTimeout(timer); // Clear the timeout if the component unmounts or if open becomes true again
    };
  }, [openLeftBar]);

  const navMap: Array<{
    name: string;
    img: ImgGenerator;
    action: string;
    requiresLogin: boolean;
  }> = [
    {
      name: t("Products"),
      action: "products",
      img: (isActive: boolean, isLoggedIn: boolean) => (
        <Products isActive={isActive} isLoggedIn={isLoggedIn} />
      ),
      requiresLogin: false,
    },
    {
      name: t("Projects"),
      action: "projects",
      img: (isActive: boolean, isLoggedIn: boolean) => (
        <Projects isActive={isActive} isLoggedIn={isLoggedIn} />
      ),
      requiresLogin: true,
    },
    {
      name: t("Graphics"),
      action: "graphics",
      img: (isActive: boolean, isLoggedIn: boolean) => (
        <Graphics isActive={isActive} isLoggedIn={isLoggedIn} />
      ),
      requiresLogin: false,
    },
    {
      name: t("Text"),
      action: "text",
      img: (isActive: boolean, isLoggedIn: boolean) => (
        <Text isActive={isActive} isLoggedIn={isLoggedIn} />
      ),
      requiresLogin: false,
    },
    {
      name: t("Bin"),
      action: "bin",
      img: (isActive: boolean, isLoggedIn: boolean) => (
        <DeleteIcon
          style={{
            color: !isLoggedIn
              ? "#808d98" // User not logged in, color stays #808d98
              : isActive
              ? "#808d98" // When active and logged in, color is #808d98
              : "white", // User logged in and not active, color is white
          }}
        />
      ),
      requiresLogin: true,
    },
  ];
  const location = useLocation();
  const { setLastDesignStudioLink } = useContext(AppContext);

  useEffect(() => {
    // Check if the current path starts with 'design-studio'
    if (location.pathname.startsWith("/design-studio")) {
      // Get everything after "/design-studio/"
      const fullLocation = location.pathname.slice("/design-studio/".length);
      setLastDesignStudioLink(`${"design-studio/"}${fullLocation}`);
    }
  }, [location.pathname]);

  return (
    <div className={s.container}>
      {navMap.map((el) => {
        const isUserLoggedIn = !!user;

        const itemContent = (
          <div
            key={uuid()}
            onClick={() => handleItemClick(el.name, el.action, el.requiresLogin)}
            className={`${s.el} ${el.name === activeItem ? s.active : ""} ${
              el.requiresLogin && !isUserLoggedIn ? s.disabled : ""
            }`}
          >
            {el.img(el.name === activeItem, el.requiresLogin && isUserLoggedIn)}
            <span>{el.name}</span>
          </div>
        );

        return el?.requiresLogin && !isUserLoggedIn ? (
          <Tooltip title={t("RegisterHint")} key={uuid()}>
            {itemContent}
          </Tooltip>
        ) : (
          itemContent
        );
      })}

      <RenderAction
        action={action}
        slideDirection={slideDirection}
        activeItem={activeItem}
        open={openLeftBar}
        setActiveItem={setActiveItem}
        setOpen={setOpenLeftBar}
      />
    </div>
  );
};

export default LeftBar;
