import { fabric } from "fabric";
import { extendImageObject } from "../ExtendObject";
import createFollowerImage from "./createFollowers/CreateFollowerImage";
import sortCanvasObjectsByLevel from "../SortCanvasObjectsByLevel";
import { updateCanvasObjectsList } from "../UpdateCanvasObjectList";

const getMaxOrder = (canvas: any, level: number) => {
  let maxOrder = 0;
  canvas.getObjects().forEach((obj: any) => {
    if (obj.level === level && obj.order > maxOrder) {
      maxOrder = obj.order;
    }
  });
  return maxOrder;
};

const addImage = async (canvas: fabric.Canvas | null, imageInfo: any, setCanvasObjects: any) => {
  if (canvas && imageInfo && imageInfo.url !== "") {
    try {
      const img = await new Promise<fabric.Image>((resolve, reject) => {
        fabric.Image.fromURL(
          imageInfo.url,
          (img) => {
            if (!img) {
              reject(new Error("Failed to load image"));
              return;
            }
            resolve(img);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      });

      img.set({
        left: 0,
        top: 0,
        originX: "center",
        originY: "center",
      });
      // Get canvas dimensions
      const canvasWidth = canvas.getWidth();
      const canvasHeight = canvas.getHeight();

      // Get image dimensions
      const imgWidth: any = img.width;
      const imgHeight: any = img.height;

      let scale = 1;

      if (imgWidth > canvasWidth || imgHeight > canvasHeight) {
        const widthScale = canvasWidth / imgWidth;
        const heightScale = canvasHeight / imgHeight;
        scale = Math.min(widthScale, heightScale);
      }

      // Scale the image
      img.scale(scale);
      // Center the image on the canvas
      img.set({
        left: 0,
        top: 0,
        originX: "center",
        originY: "center",
      });

      const maxOrder = getMaxOrder(canvas, imageInfo.level);
      const extendedImg = extendImageObject(img, { ...imageInfo, order: maxOrder + 1 });

      if (imageInfo.level === 4) {
        const cloneSettings = {
          id: `${extendedImg.id}-clone`,
          name: `${extendedImg.name}-clone`,
          level: 3,
          order: maxOrder + 1,
        };

        const clonedImg = await createFollowerImage(extendedImg, cloneSettings);
        clonedImg.set({
          left: 0,
          top: 0,
          originX: "center",
          originY: "center",
        });
        canvas.add(clonedImg);
      }

      canvas.add(extendedImg);
      canvas.renderAll(); // Render the canvas to see the changes

      // Update the canvas object list and sort by level
      updateCanvasObjectsList(canvas, setCanvasObjects);
      sortCanvasObjectsByLevel(canvas);
    } catch (error) {
      console.error("Error adding image to canvas:", error);
    }
  }
};

export default addImage;
