import React, { createContext, useState, ReactNode, useEffect, useRef, useContext } from "react";
import AuthContext from "./AuthContext";

interface CanvasSize {
  width: number;
  height: number;
}

interface Image {
  imgUrl: string;
  imgName: string;
  level: number | null;
  fileType?: string;
}
interface IActiveGlobalState {
  canvasType: "templates" | "projects" | null;
  fullJSON: any | null;
  selectedView: any | null;
  generateThumbnail: any | string;
}
interface FabricJSContextValue {
  canvasContext: any;
  setCanvasContext: any;
  canvasRefs: any;
  canvasContainerRefs: any;
  canvasObjectList: any;
  setCanvasObjectList: any;
  isWorkingArea: boolean;
  setIsWorkingArea: any;
  canvasSize: any;
  setCanvasSize: any;
  setCanvasJSONContext: any;
  canvasJSONContext: any;

  setCanvasActiveObject: any;
  canvasActiveObject: any;
  setActiveGlobalState: any;
  activeGlobalState: IActiveGlobalState;
  styleIsActive: any;
  setStyleIsActive: any;
  creatingNewWork: any;

  setCreatingNewWork: React.Dispatch<React.SetStateAction<any>>;

  setTempActiveGlobalState: any;
  tempActiveGlobalState: any;
}

const defaultContextValueNew: FabricJSContextValue = {
  canvasContext: null,
  setCanvasContext: () => {},
  canvasRefs: null,
  canvasContainerRefs: null,
  canvasObjectList: [],
  setCanvasObjectList: () => {},
  isWorkingArea: false,
  setIsWorkingArea: () => {},
  canvasSize: {},
  setCanvasSize: {},
  setCanvasJSONContext: () => {},
  canvasJSONContext: {},
  setCanvasActiveObject: () => {},
  canvasActiveObject: null,
  setActiveGlobalState: () => {},
  activeGlobalState: {
    canvasType: null,
    fullJSON: null,
    selectedView: null,
    generateThumbnail: "",
  },
  styleIsActive: null,
  setStyleIsActive: () => {},
  creatingNewWork: { newWorkImageUrl: "", workType: "" },
  setCreatingNewWork: () => {},
  setTempActiveGlobalState: () => {},
  tempActiveGlobalState: null,
};

const FabricJSContextNew = createContext<FabricJSContextValue>(defaultContextValueNew);

interface TFabricJSContextProviderProps {
  children: ReactNode;
}
const activeGlobalStateInitialValue = {
  canvasType: null,
  fullJSON: null,
  selectedView: null,
  generateThumbnail: "",
};
export const FabricJSContextProviderNew: React.FC<TFabricJSContextProviderProps> = ({
  children,
}) => {
  const [canvasContext, setCanvasContext] = useState<any>();
  const canvasRefs = [
    useRef<HTMLCanvasElement>(null),
    useRef<HTMLCanvasElement>(null),
    useRef<HTMLCanvasElement>(null),
  ];
  const canvasContainerRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  //controlls if someone can add extra level 1 object
  const [isWorkingArea, setIsWorkingArea] = useState<boolean>(false);

  // canvas objects are used to render actual object list inside workshop right menu and update actual state
  const [canvasObjectList, setCanvasObjectList] = useState<any[]>([]);
  const [canvasJSONContext, setCanvasJSONContext] = useState(null);
  const [canvasSize, setCanvasSize] = useState<any>({
    width: 0,
    height: 0,
  });

  const [canvasActiveObject, setCanvasActiveObject] = useState<any>({
    objectJSON: null,
    newProperties: {},
  });
  const [styleIsActive, setStyleIsActive] = useState(null);
  //active global state holds canvas project|product, it is responsible for rendering correct components
  //based on project or product
  const [activeGlobalState, setActiveGlobalState] = useState<IActiveGlobalState>(
    activeGlobalStateInitialValue
  );

  //newWorkImageUrl is a workaround to load image inside fabric js when state is new as we are not able to fetch new product or projects
  //since tokens are not respponsind due to the flag,

  //temp canvas network is used for temporarly holding canvasState when we create a new Project
  //it holds the data neded to filter objects that will later be added to filtering  methods
  const [tempActiveGlobalState, setTempActiveGlobalState] = useState<any>({});

  //create new work is used to add image to canvas when we create new work
  //when user adds an image and creates ether product or project
  //that image is not present inside layers as it has to be saved for it to be present
  const [creatingNewWork, setCreatingNewWork] = useState<any>({
    newWorkImageUrl: "",
    new: false,
    workType: "",
  });

  const exemptPathNames = ["/dashboard", "/projects", "/products"];

  useEffect(() => {
    const handleClickOutside = () => {
      setStyleIsActive(null);
    };

    if (exemptPathNames.some((path) => window.location.pathname === path)) {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [setStyleIsActive]);

  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (!user) {
      setActiveGlobalState(activeGlobalStateInitialValue);
      setCanvasContext(null);
    }
  }, [user]);

  return (
    <FabricJSContextNew.Provider
      value={{
        canvasContext,
        setCanvasContext,
        canvasRefs,
        canvasContainerRefs,
        canvasObjectList,
        setCanvasObjectList,
        isWorkingArea,
        setIsWorkingArea,
        canvasSize,
        setCanvasJSONContext,
        canvasJSONContext,

        setCanvasActiveObject,
        canvasActiveObject,
        setCanvasSize,
        setActiveGlobalState,
        activeGlobalState,
        styleIsActive,
        setStyleIsActive,
        creatingNewWork,
        setCreatingNewWork,

        setTempActiveGlobalState,
        tempActiveGlobalState,
      }}
    >
      {children}
    </FabricJSContextNew.Provider>
  );
};

export default FabricJSContextNew;
