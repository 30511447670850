import React, { useContext, useState } from "react";
import s from "./TopTools.wrapper.module.scss";

import RenderAction from "./RenderAction";
import AppContext from "../../../../../../core/context/AppContext";
const TopTools = () => {
  const { isLoadingSave, openLeftBar } = useContext(AppContext);

  return (
    <div className={`${s.container} ${openLeftBar ? s.open : ""}`}>
      <RenderAction isLoading={isLoadingSave} />
    </div>
  );
};

export default TopTools;
