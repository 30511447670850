import React from "react";
import s from "./AddIconButton.module.scss";
import { Tooltip } from "@mui/material";

interface IAddIconButton {
  handleClick: () => void;
  Icon: React.ElementType;
  tooltip: string;
}

const AddIconButton = ({ handleClick, Icon, tooltip }: IAddIconButton) => {
  return (
    <div className={s.container} onClick={() => handleClick()}>
      <Tooltip title={tooltip}>
        <Icon fontSize="large" />
      </Tooltip>
    </div>
  );
};

export default AddIconButton;
