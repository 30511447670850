import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import s from "./Graphics.module.scss";

import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import { useApiRequest } from "../../../../../core/api/useApiRequest";
import {
  uniqueID,
  useNotification,
} from "../../../../../core/context/notifications/NotificationProvider";
import { useTranslation } from "react-i18next";
import GraphicsContext from "../../../../../core/context/GraphicsContext";
import addGateway from "../../../../../features/Fabric-JS/fabric-functions/AddToCanvas/AddGateway";
import { generateRandomHexString } from "../../../../../helper/generateRandomHexString";
import DisplayPrevious from "../components/DisplayPrevious";
import ConvertToVectorModal from "../../../../Modals/ConvertToVector/ConvertToVectorModal";
import ModalWrapper from "../../../../Modals/Modal.wrapper";
import NormalModal from "../../../../Modals/Modal/NormalModal";
import Button from "../../../../Button/Button";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import GraphicComponent from "../../../../GraphicComponent/GraphicComponent";
import ExpandableFolderList from "../../../../FolderComponents/ExpandableFolderList/ExpandableFolderList";
import AuthContext from "../../../../../core/context/AuthContext";
interface IGraphics {
  url: string;
  fileType: string;
  name: string;
}
const GraphicsLeftBar = () => {
  const [age, setAge] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const { canvasContext, setCanvasObjectList, activeGlobalState, canvasObjectList } =
    useContext(FabricJSContextNew);
  const { sendRequest } = useApiRequest();
  const notification = useNotification();
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [addedImages, setAddedImages] = useState<any>([]);

  const [fileToUpload, setFileToUpload] = useState<any>(null);

  const [askVectorModal, setAskVectorModal] = useState<boolean>(false);
  const levelBased = activeGlobalState.canvasType === "templates" ? 1 : 4;
  const { user } = useContext(AuthContext);
  const sendFileHandler = async (fileData: any) => {
    if (user) {
      const formData = new FormData();
      formData.append("attachment", fileData.file);
      formData.append("draft_id", fileData.draft_id);
      try {
        const response: any = await sendRequest("post", "/upload-pending-attachment", formData);
        if (!response) {
          notification({
            id: uniqueID,
            type: "ERROR",
            message: "USP something went wrong",
          });
        }
        if (response) {
          setImageUrl(response.url);
          setAddedImages((prevImages: any) => [
            ...prevImages,
            { url: response.url, name: fileData?.name || "new image", fileType: "img" },
          ]);

          //construct image to add info is an object that is required for addGateway -> addImage to process onto canvas
          const constructImageToAddInfo = {
            url: response.url,
            name: fileData?.name || "new image",
            fileType: "img",
            level: levelBased,
          };
          addGateway(canvasContext, constructImageToAddInfo, setCanvasObjectList);

          notification({
            id: uniqueID,
            type: "SUCCESS",
            message: "Graphic has been added",
          });
          setTimeout(() => {
            setFetchGraphics((prevState) => !prevState);
          }, 1000);
        }

        return response;
      } catch (err) {
        console.error(err);
        notification({
          id: uniqueID,
          type: "ERROR",
          message: "USP something went wrong",
        });
        return null;
      }
    } else {
      const reader = new FileReader();
      const dataUrl: any = reader.readAsDataURL(fileToUpload); // Read the file as a data URL
      const constructImageToAddInfo = {
        url: dataUrl,
        name: fileData?.name || "new image",
        fileType: "img",
        level: 4,
      };
      addGateway(canvasContext, constructImageToAddInfo, setCanvasObjectList);
    }
  };
  const sendFileToConvertHandler = async (fileData: any) => {
    const formData = new FormData();
    formData.append("file", fileData.file);
    try {
      const response = await sendRequest("post", "http://localhost:3000/convert", formData);
      if (!response) {
        notification({
          id: uniqueID,
          type: "ERROR",
          message: "USP something went wrong",
        });
      }
      if (response) {
        setImageUrl(response.url);
        setAddedImages((prevImages: any) => [
          ...prevImages,
          { url: response.url, name: fileData?.name || "converted image", fileType: "img" },
        ]);

        notification({
          id: uniqueID,
          type: "SUCCESS",
          message: "Graphic has been converted and added",
        });
      }

      return response;
    } catch (err) {
      console.error(err);
      notification({
        id: uniqueID,
        type: "ERROR",
        message: "USP something went wrong",
      });
      return null;
    }
  };
  const [fetchGraphc, setFetchGraphics] = useState<boolean>(false);

  const createGraphic = async (e: any) => {
    const file = e.target.files[0];

    if (!file) {
      console.error("No file selected.");
      return;
    }

    if (!user) {
      // Logic for when user is not logged in or available
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataUrl: any = event.target?.result;
        const constructImageToAddInfo = {
          url: dataUrl,
          name: file.name || "new image",
          fileType: "img",
          level: 4,
        };

        addGateway(canvasContext, constructImageToAddInfo, setCanvasObjectList);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file); // Read the file as a data URL
    } else {
      // Logic for logged-in user
      if (file.type !== "image/svg+xml") {
        setFileToUpload(file);
        setAskVectorModal(true);
      } else {
        const draft_id = generateRandomHexString(32);
        const fileData = { file, draft_id, name: file.name };
        await sendFileHandler(fileData);
      }
    }
  };

  const handleVectorModalClose = (option: string) => {
    setAskVectorModal(false);
    const draft_id = generateRandomHexString(32);
    const fileData = { file: fileToUpload, draft_id, name: fileToUpload.name };

    if (option === "Yes") {
      sendFileToConvertHandler(fileData);
    } else {
      sendFileHandler(fileData);
    }
  };
  const levelBasedOnCanvasType =
    activeGlobalState.canvasType === "templates"
      ? 1
      : activeGlobalState.canvasType == "projects"
      ? 4
      : null;

  const [selectedFolder, setSelectedFolder] = useState<any>({ id: 0 });

  const handleAddGraphics = (el: IGraphics) => {
    const graphcsInfo = {
      level: levelBasedOnCanvasType,
      fileType: "img",
      url: el.url,
      name: el.name,
    };

    addGateway(canvasContext, graphcsInfo, setCanvasObjectList);
  };

  const handleAddText = (el: any) => {
    const textinfo = {
      fontSize: el?.style?.fontSize || 16,
      text: el.text,
      color: el?.style?.color || "#000000",
      fileType: "text",
      level: levelBasedOnCanvasType,
    };

    addGateway(canvasContext, textinfo, setCanvasObjectList);
  };

  return (
    <>
      <div className={s.container}>
        {user ? (
          <>
            <TextField
              className={s.input}
              size="small"
              variant="outlined"
              label="Szukaj"
              sx={{
                ".MuiOutlinedInput-root": {
                  borderRadius: "4px",
                },
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" style={{ textAlign: "center" }}>
                Search
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                size="small"
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <h4>{t("LastUsed")}</h4>
          </>
        ) : null}
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/media"
            defaultSort="id"
            defaultPagination={15}
            isPagination={false}
            forceFetch={fetchGraphc}
            render={({ data, onDelete }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <GraphicComponent
                    key={`dashboard-product-products-all-map${i}`}
                    imgSrc={el.url}
                    variant="2ex_small"
                    handleClick={() => handleAddGraphics(el)}
                    id={`dashboard-product-products-all-map${i}`}
                    onDelete={onDelete}
                    graphic={el}
                    componentOptions={"1"}
                  />
                ))}
              </div>
            )}
          />
        </div>
        {/* <div className={s.folder_div}>
          <ExpandableFolderList
            requestPath="projects"
            setSelectedFolder={setSelectedFolder}
            selectedFolder={selectedFolder}
          />
        </div> */}
        <div className={s.list_container}>
          <FilterComponent
            endpoint="/media"
            defaultSort="id"
            defaultPagination={15}
            isPagination={true}
            forceFetch={fetchGraphc}
            // category_id={selectedFolder?.toString()}
            render={({ data, onDelete }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <GraphicComponent
                    key={`dashboard-product-products-all-map${i}`}
                    imgSrc={el.url}
                    variant="2ex_small"
                    handleClick={() => {}}
                    id={`dashboard-product-products-all-map${i}`}
                    onDelete={onDelete}
                    graphic={el}
                    componentOptions={"2"}
                  />
                ))}
              </div>
            )}
          />
        </div>
        <Button text={t("Select file")} type="input" onFileChange={(e) => createGraphic(e)} />
      </div>
      <NormalModal open={askVectorModal} setOpen={setAskVectorModal}>
        <ModalWrapper setOpen={setAskVectorModal}>
          <ConvertToVectorModal
            setCloseModal={setAskVectorModal}
            handleOptionSelect={handleVectorModalClose}
          />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default GraphicsLeftBar;
