import React, { useCallback, useContext, useState } from "react";

import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import addWARect from "../../../../../fabric-functions/WorkingAreaLogic/RectWA";
import addWACircle from "../../../../../fabric-functions/WorkingAreaLogic/CircleWA";
import { exportCanvasToImage, exportWorkingAreaToImage } from "../../../../../fabric-functions/ExportCanvasToImage";

const DevMode = () => {
  const { canvasContext, setCanvasObjectList } = useContext(FabricJSContextNew);
  const [jsonOutput, setJsonOutput] = useState("");
  //   const addMainObject = useCallback(() => {
  //     const addObject = {
  //       url: test,
  //       name: "tshirt",
  //       level: 1,
  //       fileType: "img",
  //     };
  //     addGateway(canvasContext, addObject, setCanvasObjectList);
  //   }, [canvasContext, setCanvasObjectList]);

  const handleAddWARect = useCallback(() => {
    addWARect(canvasContext);
  }, [canvasContext]);

  const handleAddWACircle = useCallback(() => {
    addWACircle(canvasContext);
  }, [canvasContext]);

  const handleSaveCanvas = useCallback(() => {
    const canvasJson = canvasContext.toJSON();
    setJsonOutput(JSON.stringify(canvasJson, null, 2));
  }, [canvasContext]);

  return (
    <div>
      {/* <button onClick={addMainObject}>add main object</button> */}
      <button style={{ marginTop: "30px" }} onClick={handleAddWARect}>
        Create Workable Area
      </button>
      <button style={{ marginTop: "30px" }} onClick={handleAddWACircle}>
        Create Workable Area Circle
      </button>
      <button onClick={handleSaveCanvas}> save working area</button>
      <button onClick={() => exportCanvasToImage(canvasContext)}>Export canvas to image</button>
      <button onClick={() => exportWorkingAreaToImage(canvasContext)}>
        Export working area to image
      </button>
      <pre>{jsonOutput}</pre>
    </div>
  );
};

export default DevMode;
