import React, { useContext, useEffect, useState } from "react";
import WorkShopProduct from "../../../Design-Studio/FabricJS/components/WorkShopProduct/WorkShopProduct";
import WorkShopProject from "../../../Design-Studio/FabricJS/components/WorkShopProject/WorkShopProject";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import Button from "../../../../../components/Button/Button";
import s from "../FabricJSFreeStudio.module.scss";

const ControllContainer = () => {
  const { setActiveGlobalState, canvasObjectList, canvasContext } = useContext(FabricJSContextNew);

  const [workShopType, setWorkShopType] = useState<string>("product");

  useEffect(() => {
    setWorkShopType("project");
    setActiveGlobalState((prevState: any) => ({ ...prevState, canvasType: "projects" }));
  }, []);

  const renderWorkshop = () => {
    if (workShopType === "project") {
      return <WorkShopProject />;
    }

    return null;
  };

  {
    renderWorkshop();
  }
  return (
    <>
      {/* <div className={s.button_div_panel}>
        <Button
          text={"Product Features"}
          type="button"
          handleClick={() => handleProductButton()}
          isFlat={true}
        />
        <Button
          text={"Project Features"}
          type="button"
          handleClick={() => handleProjectButton()}
          isFlat={true}
        />
      </div> */}
      {renderWorkshop()}
    </>
  );
};

export default ControllContainer;
