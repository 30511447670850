import React, { useContext } from "react";
import s from "../FabricJSPreview.module.scss";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import { filterVersionToShow } from "../../../../../helper/filterVersionToShow";
import SingleComponent from "../../../../../components/SingleComponent/SingleComponent";
import handleLoadCanvasProject from "../../../fabric-functions/LoadProject";
const RightPanel = () => {
  const { canvasContext, activeGlobalState, setActiveGlobalState } = useContext(FabricJSContextNew);
  const selectVersion = (data: any) => {
    if (activeGlobalState.selectedView.uuid === data.uuid) {
      return;
    }
    setActiveGlobalState((prevState: any) => {
      const newState = {
        ...prevState,
        selectedView: data,
      };

      const projectToShow = filterVersionToShow(
        newState.selectedView?.view_uuid,
        newState.selectedView?.uuid,
        newState.fullJSON,
        true
      );
      handleLoadCanvasProject(canvasContext, projectToShow.canvasJSON);
      return newState;
    });
  };
  return (
    <div className={s.views}>
      {activeGlobalState?.fullJSON &&
        activeGlobalState?.fullJSON?.versions?.map((el: any, i: number) => (
          <div key={`fabric-js-map-pages-${i}`}>
            <SingleComponent
              key={`fabric-js-map-pages-${i}`}
              variant="medium"
              handleClick={(selectedViewToShow) => selectVersion(selectedViewToShow)}
              data={el}
              isPreview={true}
            />
          </div>
        ))}
    </div>
  );
};

export default RightPanel;
