import React from "react";
import s from "./PaymentWrapper.module.scss";
import PaymentPlan from "./PaymentPlan";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const dd = [
  {
    type: "free",
    period: "na zawsze",
    description: "Dla hobbystów i tych, którzy chcą spróbować zabawy w kreatorze.",
    perks: [
      "Bezpłatny kreator nadruków on-line do pracy i zabawy",
      "Załaduj dowolny produkt i nanieść dowolne logo",
      "test 1",
      "test 2",
      "test 3",
    ],
    spaceAmount: ["10 GB", "20 GB", "30 GB"],
    price: "0",
  },
  {
    type: "free",
    period: "na zawsze",
    description: "Dla hobbystów i tych, którzy chcą spróbować zabawy w kreatorze.",
    perks: [
      "Bezpłatny kreator nadruków on-line do pracy i zabawy",
      "Załaduj dowolny produkt i nanieść dowolne logo",
      "test 1",
      "test 2",
      "test 3",
    ],
    spaceAmount: ["10 GB", "20 GB", "30 GB"],
    price: "249,00",
  },
  {
    type: "free",
    period: "na zawsze",
    description: "Dla hobbystów i tych, którzy chcą spróbować zabawy w kreatorze.",
    perks: [
      "Bezpłatny kreator nadruków on-line do pracy i zabawy",
      "Załaduj dowolny produkt i nanieść dowolne logo",
      "test 1",
      "test 2",
      "test 3",
    ],
    spaceAmount: ["10 GB", "20 GB", "30 GB"],
    price: "249,00",
  },
];

const PaymentWrapper = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <div className={s.nav} onClick={() => navigate(-1)}>
        <ChevronLeftIcon />
        <h5>{t("Change plan")}</h5>
      </div>
      <div className={s.inner_container}>
        {dd.map((el: any) => (
          <PaymentPlan el={el} handleClick={() => {}} />
        ))}
      </div>
      <div className={s.inner_container_mobile}>
        <Swiper navigation={true} modules={[Navigation]} className={s.subscription_swiper}>
          {dd.map((el: any) => (
            <SwiperSlide className={s.swiper_slide}>
              <PaymentPlan el={el} handleClick={() => {}} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PaymentWrapper;
