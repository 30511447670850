import React, { useContext, useState } from "react";
import s from "./AddImage.module.scss";
import Button from "../../../../../../../components/Button/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import { useApiRequest } from "../../../../../../../core/api/useApiRequest";
import { generateRandomHexString } from "../../../../../../../helper/generateRandomHexString";
import {
  uniqueID,
  useNotification,
} from "../../../../../../../core/context/notifications/NotificationProvider";
import { useTranslation } from "react-i18next";
import addGateway from "../../../../../fabric-functions/AddToCanvas/AddGateway";
import AuthContext from "../../../../../../../core/context/AuthContext";

interface AddImageProps {
  closeModal: () => void; // Function to close the modal
}

const AddImage: React.FC<AddImageProps> = ({ closeModal }) => {
  const { sendRequest, isLoading } = useApiRequest();
  const { canvasContext, setCanvasObjectList } = useContext(FabricJSContextNew);
  const notification = useNotification();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const uploadFileAndAddToCanvas = async (file: any) => {
    const draftId = generateRandomHexString(32);
    const formData = new FormData();
    formData.append("attachment", file);
    formData.append("draft_id", draftId);

    try {
      const response = await sendRequest("post", "/upload-pending-attachment", formData);
      if (response?.url) {
        const objectToAddInfo = {
          url: response.url,
          name: file.name,
          level: 4,
          fileType: "img",
        };

        // Add the image to the canvas after successful upload
        addGateway(canvasContext, objectToAddInfo, setCanvasObjectList);

        // Notify success
        notification({
          id: uniqueID(),
          type: "SUCCESS",
          message: t("Image uploaded and added to canvas successfully!"),
        });

        // Close the modal
        closeModal();
      } else {
        notification({
          id: uniqueID(),
          type: "ERROR",
          message: t("Something went wrong while uploading the image."),
        });
      }
    } catch (error) {
      console.error(error);
      notification({
        id: uniqueID(),
        type: "ERROR",
        message: t("Failed to upload image. Please try again."),
      });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]; // Access the selected file
    if (!file) {
      console.error("No file selected.");
      return;
    }

    if (!user) {
      // Logic for when user is not logged in
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataUrl: any = event.target?.result; // Get the data URL from FileReader
        const constructImageToAddInfo = {
          url: dataUrl,
          name: file.name || "new image",
          fileType: "img",
          level: 4,
        };

        addGateway(canvasContext, constructImageToAddInfo, setCanvasObjectList);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file); // Read the file as a data URL
    } else {
      // Logic for logged-in user
      setSelectedFile(file); // Save the selected file to state
      uploadFileAndAddToCanvas(file); // Upload and add to canvas, then close modal
    }
  };

  return (
    <div className={s.container}>
      <div className={s.header}>
        <h4>VIDELINK</h4>
      </div>
      <div className={s.top}>
        <div className={s.left}>
          <h5>{t("Upload an image (e.g. product) to start visualization")}</h5>
          <p>{t("Drag and drop an image here or")}</p>

          {/* Button to select a file, invokes file selection and triggers file upload, canvas update, and modal close */}
          <Button
            onFileChange={handleFileChange} // Handle file selection
            name={isLoading ? t("Loading") : selectedFile?.name}
            type="input"
            text={isLoading ? t("Loading") : t("Select a file from your disk")}
            isLoading={isLoading}
          />

          <p className="privacy-text">{t("Maximum file size is 100 MB.")}</p>
          <p className="privacy-text">{t("Acceptable formats: jpg, png, tiff, svg, pdf.")}</p>
        </div>
      </div>
      <div className={s.bottom}>
        <div className={s.button}>
          <ChevronLeftIcon />
          <span onClick={closeModal}>{t("Cancel")}</span>
        </div>
      </div>
    </div>
  );
};

export default AddImage;
