import React, { useContext, useState } from "react";
import s from "./EditFolderName.module.scss";
import ModalHeader from "../../ModalHeader";
import { useTranslation } from "react-i18next";
import Button from "../../../Button/Button";
import { usePostRequest } from "../../../../core/api/usePostRequest";
import { TextField } from "@mui/material";
import {
  uniqueID,
  useNotification,
} from "../../../../core/context/notifications/NotificationProvider";
import { useApiRequest } from "../../../../core/api/useApiRequest";
import AppContext from "../../../../core/context/AppContext";
const buttonStyles = {
  marginLeft: "auto",
  width: "200px",
};
interface IEditNameFolder {
  parent_id?: string;
  setCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditNameFolder = ({ parent_id, setCloseModal }: IEditNameFolder) => {
  const { setForceFetchFolder } = useContext(AppContext);
  const { t } = useTranslation();
  const notification = useNotification();

  const [input, setInput] = useState<{ name?: string; parent_id?: string }>({
    ...(parent_id && { parent_id }),
  });
  const { sendRequest } = useApiRequest();
  const { currentFolder } = useContext(AppContext);
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const changeNameHandler = async () => {
    try {
      const response = await sendRequest(
        "put",
        `creator/2d/templates/categories/${currentFolder?.id}`,
        input
      );
      if (response?.data) {
        setForceFetchFolder((prev) => !prev);
        setCloseModal((prev) => !prev);
        notification({ type: "SUCCESS", id: uniqueID(), message: "Success" });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={s.container}>
      <ModalHeader header={t("Change folders name")} />
      <TextField name="name" label={t("Folder name")} onChange={(e) => inputHandler(e)} />
      <Button
        text={t("Save")}
        type="button"
        styles={buttonStyles}
        handleClick={() => changeNameHandler()}
      />
    </div>
  );
};

export default EditNameFolder;
