interface Folder {
  id: number;
  name: string;
  parent_id: number;
  token: string;
  is_shared?: boolean;
}

interface TreeNode extends Folder {
  children: TreeNode[];
}

export function buildTree(folders: Folder[]): TreeNode[] {
  const map: { [key: number]: TreeNode } = {};
  const roots: TreeNode[] = [];

  // Initialize the map with the folder IDs as keys
  folders.forEach((folder) => {
    map[folder.id] = { ...folder, children: [] };
  });

  // Build the tree
  folders.forEach((folder) => {
    const node = map[folder.id];
    if (folder.parent_id === 0) {
      // If the parent_id is 0, it's a root node
      roots.push(node);
    } else {
      // Else, find the parent and add the current node to its children
      const parentNode = map[folder.parent_id];
      if (parentNode) {
        parentNode.children.push(node);
      } else {
        // Handle the case where the parent_id doesn't exist
        console.warn(`Parent with id ${folder.parent_id} not found for folder ${folder.id}`);
      }
    }
  });

  return roots;
}
