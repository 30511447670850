import React, { useContext, useEffect, useState } from "react";
import s from "./WorkSpace.module.scss";
import Edit from "../../../components/Edit/Edit";
import img from "../../../assets/images/profile.webp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddLink from "../../../components/AddLink/AddLink";
import AuthContext from "../../../core/context/AuthContext";
import { useTranslation } from "react-i18next";
import NormalModal from "../../../components/Modals/Modal/NormalModal";
import ModalWrapper from "../../../components/Modals/Modal.wrapper";
import EditWorkSpace from "../../../components/Modals/Settings/EditWorkSpace/EditWorkSpace";
const userApiResponse = [
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 1,
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 2,
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 3,
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 4,
  },
];
const WorkSpace = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [editWorkSpaceModal, setEditWorkSpaceModal] = useState<boolean>(false);
  const helper = [t("Team name"), t("Address url")];
  console.log(helper);

  const apiResponse = [user.workspace.name, user.workspace.hostname];
  return (
    <>
      <div className={s.container}>
        <h3>Przestrzeń robocza</h3>
        <div className={s.top}>
          {helper.map((el, i) => (
            <div className={s.row}>
              <span>{el}</span>
              <span>{apiResponse[i]}</span>
            </div>
          ))}
          <Edit handleClick={() => setEditWorkSpaceModal(true)} />
        </div>
        <h3>Zespół</h3>
        <div className={s.bottom}>
          <div className={s.row}>
            <span>Użytkownicy </span>
            <span>4/10</span>
          </div>
          {userApiResponse.map((el) => (
            <div className={s.row}>
              <img src={el.img} alt="avatar" />
              <span>{el.name}</span>
              <MoreHorizIcon
                onClick={() => {
                  // console.log("some action" + el.id);
                }}
              />
            </div>
          ))}
          <AddLink
            handleClick={() => {
              // console.log("add action");
            }}
          />
        </div>
      </div>

      <NormalModal open={editWorkSpaceModal} setOpen={setEditWorkSpaceModal}>
        <ModalWrapper setOpen={setEditWorkSpaceModal}>
          <EditWorkSpace setCloseModal={setEditWorkSpaceModal} />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default WorkSpace;
