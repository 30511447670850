export const filterVersionToShow = (
  viewUuid: string,
  versionUuid: string,
  product: any,
  lockObjects: boolean
) => {
  // Process layers from product.layers that match the versionUuid
  const productLayersJson =
    product.layers
      ?.filter((layer: any) => layer.version_uuid === versionUuid)
      .map((layer: any) => layer.json) ?? [];

  let layersJson = [...productLayersJson];

  // If product.template exists, process template layers that match the viewUuid
  if (product.template) {
    const templateLayersJson =
      product.template.layers
        ?.filter((layer: any) => layer.view_uuid === viewUuid)
        //we lock the objects so that user 2 cannot move it around
        //later we will introduce roles more specific and complex logic will be required
        .map((layer: any) => ({
          ...layer.json,
          selectable: false,
          evented: false,
        })) ?? [];
    layersJson = [...layersJson, ...templateLayersJson];
  }

  // Process layers to clone certain objects and disable level 1 objects
  const updatedLayersJson = layersJson
    .map((obj: any) => {
      if (
        (obj.type === "image" ||
          obj.type === "i-text" ||
          obj.type === "text" ||
          obj.type === "i-image") &&
        obj.level === 4
      ) {
        const clonedObj = {
          ...obj,
          id: `${obj.id}-clone`,
          name: obj.name ? `${obj.name}-clone` : undefined,
          level: 3,
          opacity: 0.5,
          selectable: true,
          evented: false,
          clipPath: undefined,
          isClone: true,
          originX: obj.originX || "center",
          originY: obj.originY || "center", // Ensure consistent originY
          left: obj.left, // Maintain original position
          top: obj.top, // Maintain original position
          scaleX: obj.scaleX, // Maintain original scale
          scaleY: obj.scaleY, // Maintain original scale
        };
        return [obj, clonedObj]; // Return both original and cloned object
      }

      // Disable level 1 objects
      if (obj.level === 1) {
        return {
          ...obj,
          selectable: false,
          evented: false,
          lockMovementX: true, // Lock movement on the X-axis
          lockMovementY: true, // Lock movement on the Y-axis
        };
      }
      // Disable level 5 objects

      if (obj.level === 5) {
        return {
          ...obj,
          selectable: false,
          evented: false,
          lockMovementX: true, // Lock movement on the X-axis
          lockMovementY: true, // Lock movement on the Y-axis
        };
      }

      return obj;
    })
    .flat(); // Flatten the array to avoid nested arrays

  const canvasJSON = { version: "5.3.0", objects: updatedLayersJson };
  return { viewUuid, canvasJSON };
};
