type Layer = {
  view_uuid: string;
  version_uuid: string;
  json: any;
};

type Product = {
  layers: Layer[];
  template?: {
    layers: Layer[];
  };
};

type CanvasObject = {
  type: string;
  level: number;
  id: string;
  name?: string;
  opacity?: number;
  selectable?: boolean;
  evented?: boolean;
  clipPath?: any;
  isClone?: boolean;
  [key: string]: any; // Allow other properties
};
const applyDisableActions = (obj: CanvasObject): CanvasObject => ({
  ...obj,
  selectable: false,
  evented: false,
  hasControls: false,
  lockMovementX: true,
  lockMovementY: true,
});
export const filterViewToShow = (
  uuid: string,
  product: Product,
  canvasType: "templates" | "projects" | null,
  disableActions?: boolean
) => {
  // Always process product.layers
  let productLayersJson = [];
  if (canvasType === "templates") {
    //we check product becouse we use this function to open newly created view for projects
    //we have to omit product.layers (laysers) as these are the project layers
    productLayersJson =
      product.layers
        ?.filter((layer) => layer.view_uuid === uuid || layer.version_uuid === uuid)
        .map((layer) => layer.json) ?? [];
  }

  let layersJson: any[] = [...productLayersJson];
  // If product.template exists, process product.template.layers and merge them

  if (product.template) {
    const templateLayersJson: any[] =
      product.template.layers
        ?.filter((layer) => layer.view_uuid === uuid || layer.version_uuid === uuid)
        .map((layer) => {
          const jsonLayer = { ...layer.json };

          // If it's a new project, disable interaction for all template layers
          if (disableActions) {
            jsonLayer.selectable = false;
            jsonLayer.evented = false;
            jsonLayer.hasControls = false;
            jsonLayer.lockMovementX = true;
            jsonLayer.lockMovementY = true;
          }

          return jsonLayer;
        }) ?? [];

    layersJson = [...layersJson, ...templateLayersJson];
  }

  // Process layers to clone certain objects and handle canvasType 'project'
  const updatedLayersJson: CanvasObject[] = layersJson
    .map((obj: CanvasObject) => {
      if (
        (obj.type === "image" ||
          obj.type === "i-text" ||
          obj.type === "text" ||
          obj.type === "i-image") &&
        obj.level === 4
      ) {
        const clonedObj: CanvasObject = {
          ...obj,
          id: `${obj.id}-clone`,
          name: obj.name ? `${obj.name}-clone` : undefined,
          level: 3,
          opacity: 0.5,
          selectable: canvasType !== "projects",
          evented: false,
          clipPath: undefined,
          isClone: true,
        };

        return [obj, clonedObj]; // Return both original and cloned object
      }
      if (obj.level === 5) {
        return {
          ...obj,
          selectable: false,
          evented: false,
          lockMovementX: true, // Lock movement on the X-axis
          lockMovementY: true, // Lock movement on the Y-axis
        };
      }
      if (obj.level === 5 || obj.level === 1 || obj.level === 2) {
        return disableActions ? applyDisableActions(obj) : obj;
      }
      // If canvasType is 'project', update attributes for all objects not to be selectable
      return {
        ...obj,
      };
    })
    .flat();
  const canvasJSON = { version: "5.3.0", objects: updatedLayersJson };
  console.log(canvasJSON, "canvasJSON ");
  return { uuid, canvasJSON };
};
