import React from "react";
import AddIcon from "@mui/icons-material/Add";
import s from "./AddLink.module.scss";
interface IAddLink {
  handleClick: () => void;
}
const AddLink = ({ handleClick }: IAddLink) => {
  return (
    <div className={s.container} onClick={() => handleClick()}>
      <AddIcon fontSize="small" />
      <span>Dodaj</span>
    </div>
  );
};

export default AddLink;
