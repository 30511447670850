import React, { useContext } from "react";
import s from "./DisplayPrevious.module.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";

import { Tooltip } from "@mui/material";
import addGateway from "../../../../../features/Fabric-JS/fabric-functions/AddToCanvas/AddGateway";

interface IDisplayPrevious {
  data: any; // data any waiting for backend what kind of data will come
}
interface IGraphics {
  url: string;
  fileType: string;
  name: string;
}
interface IText {}
const DisplayPrevious = ({ data }: IDisplayPrevious) => {
  const { canvasContext, setCanvasObjectList, activeGlobalState } = useContext(FabricJSContextNew);

  const levelBasedOnCanvasType =
    activeGlobalState.canvasType === "templates"
      ? 1
      : activeGlobalState.canvasType == "projects"
      ? 4
      : null;

  const handleAddGraphics = (el: IGraphics) => {
    const graphcsInfo = {
      level: levelBasedOnCanvasType,
      fileType: el.fileType,
      url: el.url,
      name: el.name,
    };
    console.log(el);
    addGateway(canvasContext, graphcsInfo, setCanvasObjectList);
    console.log(el);
  };

  const handleAddText = (el: any) => {
    const textinfo = {
      fontSize: el?.style?.fontSize || 16,
      text: el.text,
      color: el?.style?.color || "#000000",
      fileType: "text",
      level: levelBasedOnCanvasType,
    };

    addGateway(canvasContext, textinfo, setCanvasObjectList);
  };

  const handleDragStart = (e: any, item: any, type: string) => {
    const dragData =
      type === "text"
        ? {
            type: "text",
            text: item.text,
            style: item.style,
          }
        : {
            type: "image",
            src: item,
          };

    e.dataTransfer.setData("text/plain", JSON.stringify(dragData));
    // console.log("Drag data:", JSON.stringify(dragData)); // Debug log
  };

  const LastUsed = () => {
    console.log(data, "last pused graphics");
    return (
      <div className={s.container}>
        <div className={s.top}>
          <span>Ostatnio używane</span>
          <span className={s.show_all}>Pokaż wszystko</span>
          <KeyboardArrowRightIcon />
        </div>
        <div className={s.img_container}>
          {data.type === "image"
            ? data?.graphics?.map((el: IGraphics, i: number) => (
                <Tooltip title={el.name}>
                  {/* <GraphicComponent /> */}
                  <img
                    key={`Leftbar-map-lastusedi-graphic${i}11`}
                    src={el.url}
                    alt={el.name}
                    draggable
                    onDragStart={(e) => handleDragStart(e, el.url, "image")}
                    onClick={() => handleAddGraphics(el)}
                  />
                </Tooltip>
              ))
            : data?.text?.map((el: any, i: number) => (
                <p
                  key={`Leftbar-map-lastused-text${i}12`}
                  style={el.style}
                  draggable
                  onDragStart={(e) => handleDragStart(e, el, "text")}
                  onClick={() => handleAddText(el)}
                >
                  {el.text}
                </p>
              ))}
        </div>
      </div>
    );
  };

  const All = () => {
    return (
      <div className={s.container}>
        <div className={s.top}>
          <span>Wszystko</span>
          <span className={s.show_all}>Pokaż wszystko</span>
          <KeyboardArrowRightIcon />
        </div>
        <div className={s.img_container}>
          {data?.type === "image"
            ? data?.graphics?.map((el: IGraphics, i: number) => (
                <img
                  key={`Leftbar-map-lastused-all-graphic${i}123`}
                  src={el?.url}
                  alt={el?.name}
                  draggable
                  onDragStart={(e) => handleDragStart(e, el?.url, "image")}
                />
              ))
            : data?.text?.map((el: any, i: any) => (
                <p
                  key={`Leftbar-map-lastused-all-text${i}11234`}
                  style={el.style}
                  draggable
                  onDragStart={(e) => handleDragStart(e, el, "text")}
                  onClick={() => handleAddText(el)}
                >
                  {el?.text}
                </p>
              ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <LastUsed />
      <All />
    </>
  );
};

export default DisplayPrevious;
