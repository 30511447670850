import React, { useContext, useState } from "react";
import SlideUpModal from "../../../../Modals/Modal/SildeUpModal";
import CreateProject from "../../../../Modals/CreateProject/CreateProject";
import s from "./ProjectLeftBar.module.scss";
import ProjectComponent from "../../../../ProjectComponent.tsx/ProjectComponent";
import ProjectContext from "../../../../../core/context/ProjectContext";
import Button from "../../../../Button/Button";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../../core/context/AuthContext";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import { useNavigate } from "react-router-dom";
import handleLoadCanvasProject from "../../../../../features/Fabric-JS/fabric-functions/LoadProject";
import ProductComponent from "../../../../ProductComponent/ProductComponent";
import FilterComponent from "../../../../FilterComponent/FilterComponent";
import ExpandableFolderList from "../../../../FolderComponents/ExpandableFolderList/ExpandableFolderList";
const ProjectLeftBar = () => {
  const [openProjectCreate, setOpenProjectCreate] = useState<boolean>(false);
  const { setActiveGlobalState, canvasContext } = useContext(FabricJSContextNew);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const correctPath = !!user ? "design-studio" : "free-editor";

  const projectHandler = (selectedView: any, wholeProduct: any) => {
    handleLoadCanvasProject(canvasContext, selectedView);
    setActiveGlobalState({ canvasType: "templates", fullJSON: wholeProduct });
    navigate(`/design-studio/product/${wholeProduct?.token}`);
  };
  const [selectedFolder, setSelectedFolder] = useState<any>({ id: 0 });
  return (
    <>
      <div className={s.container}>
        <h4>{t("LastUsed")}</h4>
        <div className={s.products_container}>
          <FilterComponent
            endpoint="/creator/2d/projects"
            defaultSort="id"
            defaultPagination={5}
            isPagination={false}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProjectComponent
                    key={`dashboard-product-products-recent-map${i}`}
                    project={el}
                    variant="2ex_small"
                    handleClick={(variant) => projectHandler(variant, el)}
                    componentOptions="2"
                    id={`dashboard-product-products-recent-map${i}`}
                  />
                ))}
              </div>
            )}
          />
        </div>
        <div className={s.folder_div}>
          <ExpandableFolderList
            requestPath="projects"
            setSelectedFolder={setSelectedFolder}
            selectedFolder={selectedFolder}
          />
        </div>
        <div className={s.products_container}>
          <FilterComponent
            endpoint="/creator/2d/projects"
            defaultSort="id"
            defaultPagination={10}
            isPagination={true}
            category_id={selectedFolder?.id}
            render={({ data }) => (
              <div className={s.map_container}>
                {data?.map((el: any, i: number) => (
                  <ProjectComponent
                    key={`dashboard-product-products-all-map${i}`}
                    project={el}
                    variant="2ex_small"
                    handleClick={(variant) => projectHandler(variant, el)}
                    componentOptions="2"
                    id={`dashboard-product-products-all-map${i}`}
                  />
                ))}
              </div>
            )}
          />
        </div>

        <div className={s.button_container}>
          <Button
            type="button"
            text={t("New Product")}
            handleClick={() => setOpenProjectCreate(true)}
          />
        </div>
      </div>
      <SlideUpModal open={openProjectCreate} onClose={() => setOpenProjectCreate(false)}>
        <CreateProject setCloseModal={setOpenProjectCreate} />
      </SlideUpModal>
    </>
  );
};

export default ProjectLeftBar;
