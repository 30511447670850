import React from "react";
import s from "./Button.module.scss";
import LoadingState from "../LoadingState/LoadingState";

interface IButtonProps {
  handleClick?: () => void;
  onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  type: "button" | "input";
  styles?: React.CSSProperties;
  disabled?: boolean;
  name?: string;
  isLoading?: boolean;
  isFlat?: boolean;
  onFileSelectedAndInvoke?: (file: File | null) => void; // New prop
}

const Button: React.FC<IButtonProps> = ({
  handleClick,
  onFileChange,
  text,
  type,
  styles = {},
  disabled = false,
  name,
  isLoading = false,
  isFlat = false,
  onFileSelectedAndInvoke, // New prop
}) => {
  const handleClickInternal = () => {
    if (!disabled && handleClick) {
      handleClick();
    }
  };

  const handleFileChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    // Call the provided file change handler if exists
    if (onFileChange) {
      onFileChange(event);
    }

    // Invoke the passed function if provided with the selected file
    if (onFileSelectedAndInvoke) {
      onFileSelectedAndInvoke(file);
    }
  };

  const computedButtonStyles = {
    ...styles,
    ...(disabled && { cursor: "not-allowed", opacity: 0.9 }),
  };

  const renderButtonContent = () => (
    <div
      className={s.button}
      style={{
        ...computedButtonStyles,
        ...(isFlat && { padding: "10px 50px" }),
      }}
    >
      {text}
    </div>
  );

  return type === "input" ? (
    <label className={`${s.container} ${name ? s.selectedName : ""}`} style={computedButtonStyles}>
      {isLoading ? (
        <LoadingState text={text} />
      ) : (
        <>
          <input
            type="file"
            onChange={handleFileChangeInternal} // Updated to use internal handler
            style={{ display: "none" }}
            disabled={disabled}
          />
          {renderButtonContent()}
        </>
      )}
    </label>
  ) : (
    <div className={s.container} style={computedButtonStyles} onClick={handleClickInternal}>
      {isLoading ? <LoadingState text={text} /> : renderButtonContent()}
    </div>
  );
};

export default Button;
