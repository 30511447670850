import React, { useContext } from "react";
import s from "./ProductList.module.scss";

import ProductComponent from "../../../components/ProductComponent/ProductComponent";
import DashboardTopHeader from "../../../components/DashboardTopHeader/DashboardTopHeader";
import { useNavigate } from "react-router-dom";
import FabricJSContextNew from "../../../core/context/FabricJSContextNew";
import { useTranslation } from "react-i18next";
import ProductContext from "../../../core/context/ProductContext";
import FilterComponent from "../../../components/FilterComponent/FilterComponent";
const ProductList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setActiveGlobalState } = useContext(FabricJSContextNew);

  const { products } = useContext(ProductContext);

  const productHandler = (selectedView: any, fullJSON: any) => {
    setActiveGlobalState({ canvasType: "templates", fullJSON: fullJSON });
    navigate(`/design-studio/product/${fullJSON.token}`);
  };

  return (
    <div className={s.container}>
      <DashboardTopHeader header={t("Products")} navigateTo={"/products"} />
      <div className={s.list_container}>
        <FilterComponent
          endpoint="/creator/2d/templates"
          defaultSort="id"
          defaultPagination={5}
          isPagination={false}
          render={({ data, onDelete }) => (
            <div className={s.map_container}>
              {data?.map((el: any, i: number) => (
                <ProductComponent
                  key={`dashboard-product-products-recent-map${i}`}
                  product={el}
                  variant="medium"
                  handleClick={(variant) => productHandler(variant, el)}
                  componentOptions="1"
                  id={`dashboard-product-products-recent-map${i}`}
                  onDelete={onDelete}
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ProductList;
