import React, { useContext, useState } from "react";
import s from "./ChangePassword.module.scss";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthContext from "../../../core/context/AuthContext";
import { validateEmail } from "../../../helper/validation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Cookies from "js-cookie";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import { useApiRequest } from "../../../core/api/useApiRequest";
const buttonStyles = { width: "100%" };
const ChangePassword = () => {
  const [input, setInput] = useState<any>({
    email: "",
  });
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useNotification();
  const { sendRequest, isLoading } = useApiRequest();
  const { token } = useParams();
  const { setUser } = useContext(AuthContext);
  const email = searchParams.get("email");
  const { setReloadFlagAuth } = useContext(AuthContext);
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
      email: email,
      token,
    });
  };

  console.log(token);
  const handleChangePassword = async () => {
    if (!isEmailValid) return;

    const responseData: any = await sendRequest("post", "auth/password/reset", input);
    console.log(responseData);
    if (responseData) {
      notification({ type: "SUCCESS", id: uniqueID, message: t("Auth.PasswordRecovered") });
      Cookies.set("vl_access_token", responseData.access_token);
      setReloadFlagAuth((prev: boolean) => !prev);
      navigate("/dashboard");
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <h2>VIDELINK</h2>

          <span className={s.slogan}>{t("Change password")}</span>

          <TextField
            name="email"
            onChange={(e) => inputHandler(e)}
            className={s.input}
            id="outlined-basic"
            autoComplete="off"
            label={t("Address email")}
            variant="outlined"
            color="primary"
            value={email}
            error={!isEmailValid}
            onBlur={() => setIsEmailValid(validateEmail(input.email))}
          />

          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => inputHandler(e)}
            className={s.input}
            label={t("Password")}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="password_confirmation"
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => inputHandler(e)}
            className={s.input}
            label={t("Repeat password")}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            text={isLoading ? t("Loading") : t("Save new password")}
            type="button"
            handleClick={() => handleChangePassword()}
            styles={buttonStyles}
            isLoading={isLoading}
          />
          <Link to="/login">{t("Back to login")}</Link>
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default ChangePassword;
