import React, { useContext } from "react";
import s from "../FabricJSPreview.module.scss";
import { useTranslation } from "react-i18next";

import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import LeftIcon from "../../../../../components/LeftIcon/LeftIcon";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import DownloadIcon from "@mui/icons-material/Download";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import AuthContext from "../../../../../core/context/AuthContext";
import { useNavigate } from "react-router-dom";
import { exportCanvasToImage, exportWorkingAreaToImage } from "../../../fabric-functions/ExportCanvasToImage";
const LeftPanel = () => {
  const { t } = useTranslation();
  const { canvasContext, activeGlobalState } = useContext(FabricJSContextNew);
  console.log(activeGlobalState);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const leftPanelMap = [
    {
      icon: DownloadIcon,
      toolTipName: t("LeftPanel.DownloadDisc"),
      function: () => {
        exportCanvasToImage(canvasContext);
      },
    },
    {
      icon: PrintIcon,
      toolTipName: t("LeftPanel.DownloadPrint"),
      function: () => {
        exportWorkingAreaToImage(canvasContext);
      },
    },
    {
      icon: EditIcon,
      toolTipName: t("Edit"),
      function: () => {
        navigate(`/design-studio/project/${activeGlobalState.fullJSON.token}`);
      },
    },
  ];

  const leftBottomPanel = [
    {
      icon: ZoomInIcon,
      toolTipName: t("LeftPanel.ZoomIn"),
      function: () => {
        console.log("hello");
      },
    },
    {
      icon: ZoomOutIcon,
      toolTipName: t("LeftPanel.ZoomOut"),
      function: () => {
        console.log("hello");
      },
    },
    {
      icon: PhotoSizeSelectActualIcon,
      toolTipName: t("LeftPanel.OneToOne"),
      function: () => {},
    },
  ];
  return (
    <>
      <div className={s.left_top_panel}>
        {leftPanelMap.map((el, index) =>
          index !== 2 ? (
            <LeftIcon
              key={index}
              toolTipName={el.toolTipName}
              Icon={el.icon}
              handleClick={el.function}
            />
          ) : user && index === 2 ? (
            <LeftIcon
              key={index}
              toolTipName={el.toolTipName}
              Icon={el.icon}
              handleClick={el.function}
            />
          ) : null
        )}
      </div>

      <div className={s.left_bottom_panel}>
        {leftBottomPanel.map((el, index) => (
          <LeftIcon
            key={index}
            toolTipName={el.toolTipName}
            Icon={el.icon}
            handleClick={el.function}
          />
        ))}
      </div>
    </>
  );
};

export default LeftPanel;
