import React, { useState } from "react";
import img from "../../../assets/images/profile.webp";
import s from "./ShareItem.module.scss";
import CheckIcon from "@mui/icons-material/Check";
import TopOfModal from "../TopOfModal";
import uuid from "react-uuid";
const userApiResponse = [
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 1,
    company: "Linkedin",
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 2,
    company: "Linkedin",
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 3,
    company: "Linkedin",
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 4,
    company: "Linkedin",
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 4,
    company: "Linkedin",
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 4,
    company: "Linkedin",
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 4,
    company: "Linkedin",
  },
  {
    name: "Daniel Chochlinski",
    img: img,
    id: 4,
    company: "Linkedin",
  },
];
const ShareItem = () => {
  const [send, setSend] = useState(false);
  return (
    <div className={s.container}>
      <TopOfModal header={"Udostępnij link znajomym"} link={"some link"} />
      <p>Zaproszeni do Edycji</p>
      <div>
        {userApiResponse.map((el) => (
          <div className={s.item} key={uuid()}>
            <div className={s?.img_container}>
              <img alt={el.name} src={el.img} />
            </div>
            <div className={s.info}>
              <span>{el?.name}, </span>
              <span>@{el.company}</span>
            </div>
            <div onClick={() => setSend(!send)}>{send ? <CheckIcon /> : <span>SEND</span>}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShareItem;
