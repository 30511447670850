import React from "react";
import s from "./GraphicsDashboard.module.scss";
import GraphicComponent from "../../components/GraphicComponent/GraphicComponent";
import FilterComponent from "../../components/FilterComponent/FilterComponent";
import { useTranslation } from "react-i18next";

const GraphicsDashboard = () => {
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <h3>{t("Graphics")}</h3>
      <h4>{t("LastUsed")}</h4>
      <div className={s.list_container}>
        <FilterComponent
          endpoint="/media"
          defaultSort="id"
          defaultPagination={5}
          isPagination={false}
          enableSordByDate={true}
          render={({ data, onDelete }) => (
            <div className={s.map_container}>
              {data?.map((el: any, i: number) => (
                <GraphicComponent
                  key={`graphics-graphic-last-map${i}`}
                  variant="medium"
                  id={`graphics-graphic-last-map${i}`}
                  imgSrc={el?.url}
                  handleClick={() => {}}
                  onDelete={onDelete}
                  graphic={el}
                  componentOptions={"1"}
                />
              ))}
            </div>
          )}
        />
      </div>
      {/* Fetch and render all graphics */}
      <h4>{t("All")}</h4>
      <div className={s.list_container}>
        <FilterComponent
          endpoint="/media"
          defaultSort="id"
          defaultPagination={10}
          isPagination={true}
          render={({ data, onDelete }) => (
            <div className={s.map_container}>
              {data?.map((el: any, i: number) => (
                <GraphicComponent
                  key={`graphics-graphic-last-map${i}`}
                  variant="medium"
                  id={`graphics-graphic-last-map${i}`}
                  imgSrc={el?.url}
                  graphic={el}
                  handleClick={() => {}}
                  onDelete={onDelete}
                  componentOptions={"2"}
                />
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default GraphicsDashboard;
