import React, { useContext, useState } from "react";
import s from "./NoElement.module.scss";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import RepeatOneIcon from "@mui/icons-material/RepeatOne";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

import { Slider, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import { calculateMaxZoom, zoomCanvas } from "../../../../../fabric-functions/Zoom/ZoomInOut";

const NoElement = () => {
  const { t } = useTranslation();
  const { canvasContext, canvasSize } = useContext(FabricJSContextNew);

  // State to hold the current zoom factor
  const [zoom, setZoom] = useState(1);

  // Handle the slider change
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const zoomFactor = (newValue as number) / 50; // Convert slider value to zoom factor
    setZoom(zoomFactor);

    // Use the imported zoomCanvas function
    zoomCanvas(canvasContext, zoomFactor);
  };

  // Handle resetting zoom to 1:1 (original scale)
  const resetZoom = () => {
    setZoom(1); // Reset the zoom state to 1 (100%)
    zoomCanvas(canvasContext, 1); // Reset the canvas zoom to 1 (100%)
  };

  // Handle sizing max zoom
  const zoomToMaxFit = () => {
    if (canvasContext && canvasSize) {
      const maxZoom = calculateMaxZoom(canvasContext, canvasSize.width, canvasSize.height);
      setZoom(maxZoom); // Update the zoom state
      zoomCanvas(canvasContext, maxZoom); // Apply the max zoom level to the canvas
    }
  };

  return (
    <div className={s.container}>
      <div className={s.first_el}>
        <span>{t("NoElement.NoElementSelected")}</span>
      </div>

      <div>
        <Tooltip title={t("NoElement.3D")} placement="bottom">
          <ThreeDRotationIcon />
        </Tooltip>
      </div>
      <div>
        <Tooltip title={t("NoElement.FullScreen")} placement="bottom">
          <OpenInFullIcon onClick={zoomToMaxFit} />
        </Tooltip>
      </div>
      <div>
        <Tooltip title={t("NoElement.1")} placement="bottom">
          <RepeatOneIcon onClick={resetZoom} />
        </Tooltip>
      </div>

      <div>
        <Slider
          size="small"
          value={zoom * 50}
          onChange={handleSliderChange}
          aria-label="Zoom"
          valueLabelDisplay="auto"
          min={10} // Setting a reasonable min value
          max={100} // Setting a reasonable max value
        />
      </div>
    </div>
  );
};

export default NoElement;
