import React from "react";

interface IRenderActionComponent {
  action: string;
  actions: any;
}
const RenderActionComponent = ({ actions, action }: IRenderActionComponent) => {
  return <>{actions[action]}</>;
};

export default RenderActionComponent;
