import { updateCanvasObjectsList } from "../UpdateCanvasObjectList";

const hideObject = (canvas: any, objectId: any, setCanvasObjectList: any) => {
  if (canvas) {
    const objects = canvas.getObjects();
    const objectToToggle = objects.find((obj: any) => obj.id === objectId);
    const newOpacity = objectToToggle.opacity > 0 ? 0 : 1;

    objectToToggle.set({ opacity: newOpacity });

    updateCanvasObjectsList(canvas, setCanvasObjectList);
    canvas.renderAll();
  }
};

export default hideObject;
