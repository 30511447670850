import React from "react";
interface IModalHeader {
  header: string;
}
const ModalHeader = ({ header }: IModalHeader) => {
  return (
    <>
      <h3 className="modal-header-h3">{header}</h3>
    </>
  );
};

export default ModalHeader;
