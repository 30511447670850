import React from "react";
import s from "./GetQuoteModal.module.scss";
import { useTranslation } from "react-i18next";
const NotLoggedIn = () => {
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <p>{t("RegisterHint")}</p>
    </div>
  );
};

export default NotLoggedIn;
