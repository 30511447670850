import React from "react";
import { LeftBarImageI } from "../../../../types/generalTypes";

const Projects = ({ isActive, isLoggedIn }: LeftBarImageI) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27.996" viewBox="0 0 28 27.996">
      <g id="Media_Library" data-name="Media Library" transform="translate(12.002 12)">
        <g id="Group_2201" data-name="Group 2201" transform="translate(-12.002 -12)">
          <path
            id="Path_4526"
            data-name="Path 4526"
            d="M8.516,6.9a4.316,4.316,0,1,0,4.316,4.316A4.316,4.316,0,0,0,8.516,6.9Zm0,6.416a2.1,2.1,0,1,1,2.1-2.1,2.1,2.1,0,0,1-2.1,2.216Z"
            transform="translate(0.699 1.149)"
            fill={!isLoggedIn ? "#808d98" : !isActive ? "#ffff" : "#808d98"}
          />
          <path
            id="Path_4527"
            data-name="Path 4527"
            d="M28,22.747V6.3h0V4.316a1.05,1.05,0,0,0-1.05-1.05H17.964V1.05A1.05,1.05,0,0,0,16.914,0H3.266a1.05,1.05,0,0,0-1.05,1.05V3.266H1.05A1.05,1.05,0,0,0,0,4.316v1.75H0v20.88A1.05,1.05,0,0,0,1.05,28H26.829a1.05,1.05,0,0,0,1.05-1.05V23.33A1.05,1.05,0,0,0,28,22.747ZM4.316,2.1H15.864V3.15H4.316ZM25.779,25.9H9.215l8.749-10.265,7.815,7.815Zm0-19.714h0V20.414l-7.116-7.232a1.128,1.128,0,0,0-1.633,0L6.416,25.9H2.1V6.3h0V5.366h23.68Z"
            transform="translate(0)"
            fill={!isLoggedIn ? "#808d98" : !isActive ? "#ffff" : "#808d98"}
          />
        </g>
      </g>
    </svg>
  );
};

export default Projects;
