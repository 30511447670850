const createFollowerText = (textObj: any, cloneSettings: any) => {
  return new Promise<any>((resolve) => {
    textObj.clone((clonedText: any) => {
      clonedText.set({
        id: cloneSettings.id,
        level: cloneSettings.level,
        opacity: 0.5,
        selectable: true,
        evented: false,
        clipPath: undefined,
        isClone: true,
      });
      resolve(clonedText);
    });
  });
};
export default createFollowerText;
