import React, { useContext, useState } from "react";
import s from "./IncreaseFont.module.scss";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FabricJSContextNew from "../../core/context/FabricJSContextNew";
import changeText from "../../features/Fabric-JS/fabric-functions/ChangeText";

interface IIncreaseFont {
  fontSize: string;
}

const IncreaseFont = ({ fontSize }: IIncreaseFont) => {
  const [currentFontSize, setCurrentFontSize] = useState(fontSize);
  const { canvasContext, setCanvasActiveObject } = useContext(FabricJSContextNew);

  const handleChangeSize = (changeSizeArg: number) => {
    const newSize = parseInt(currentFontSize) + changeSizeArg;
    setCurrentFontSize(newSize.toString());
    changeText(canvasContext, { fontSize: newSize }, setCanvasActiveObject);
  };

  const handleManualInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = parseInt(e.target.value);
    setCurrentFontSize(newSize.toString());
    changeText(canvasContext, { fontSize: newSize }, setCanvasActiveObject);
  };

  return (
    <div className={s.container}>
      <RemoveIcon fontSize="small" onClick={() => handleChangeSize(-1)} />
      <div>
        <input type="text" value={currentFontSize} onChange={(e) => handleManualInput(e)} />
      </div>
      <AddIcon fontSize="small" onClick={() => handleChangeSize(1)} />
    </div>
  );
};

export default IncreaseFont;
