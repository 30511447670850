import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TextField } from "@mui/material";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import { useTranslation } from "react-i18next";

interface IFolder {
  id: number;
  name: string;
  parent_id: number;
  children?: [];
  // Include other fields if necessary
}

interface IFolderTreeStructure {
  requestPath: string;
  setSelectedFolder?: any;
  selectedFolder?: any;
}

const FolderTreeStructure = ({
  requestPath,
  setSelectedFolder,
  selectedFolder,
}: IFolderTreeStructure) => {
  const { sendRequest } = useApiRequest();
  const [treeData, setTreeData] = useState<TreeViewBaseItem[]>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [selectedNodeLabel, setSelectedNodeLabel] = useState<string>("");
  const [selectedNode, setSelectedNode] = useState<TreeViewBaseItem | null>(null);
  const { t } = useTranslation();

  const fetchFolders = async () => {
    try {
      const response: any = await sendRequest("get", `creator/2d/${requestPath}/categories`);
      const data: IFolder[] = response.data;

      const treeItems = buildTreeData(data);

      // Find the folder with the label "root"
      const rootNode = treeItems.find((el) => el.label.toLowerCase() === "root");

      if (rootNode) {
        setSelectedNode(rootNode);
        setSelectedNodeId(rootNode.id);
        setSelectedNodeLabel(rootNode.label);
        setSelectedFolder(Number(rootNode?.id)); // Convert to number here
      }

      setTreeData(treeItems);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  useEffect(() => {
    fetchFolders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildTreeData = (items: IFolder[]): TreeViewBaseItem[] => {
    const idToNodeMap: { [key: number]: TreeViewBaseItem } = {};

    // Initialize nodes and map them by their IDs
    items.forEach((item) => {
      idToNodeMap[item.id] = { id: item.id.toString(), label: item.name, children: [] };
    });

    const roots: TreeViewBaseItem[] = [];

    // Build the tree by assigning children to their parents
    items.forEach((item) => {
      const node = idToNodeMap[item.id];
      if (item.parent_id === 0) {
        // Root node
        roots.push(node);
      } else {
        const parent = idToNodeMap[item.parent_id];
        if (parent) {
          parent.children!.push(node);
        } else {
          console.warn(`Parent with ID ${item.parent_id} not found for item ${item.id}`);
        }
      }
    });

    // Add "Root" folder at the top of the tree
    const rootFolder: TreeViewBaseItem = {
      id: "0", // The ID for the "Root" folder
      label: "test", // The label for the "Root" folder
      children: roots, // All other root nodes are children of "Root"
    };

    return roots; // Return the new tree with "Root" at the top
  };

  const getItemId = (item: TreeViewBaseItem) => {
    return item.id;
  };

  const handleSelectedItemsChange = (event: React.SyntheticEvent, itemId: string | null) => {
    // Update the selectedNodeId state
    setSelectedNodeId(itemId);

    if (itemId == null) {
      setSelectedNode(null);
      setSelectedNodeLabel("");

      setSelectedFolder(0);
    } else {
      const findNode = (nodes: TreeViewBaseItem[]): TreeViewBaseItem | null => {
        for (let node of nodes) {
          if (node.id === itemId) {
            return node;
          }
          if (node.children && node.children.length > 0) {
            const foundNode = findNode(node.children);
            if (foundNode) return foundNode;
          }
        }
        return null;
      };

      const node = findNode(treeData);
      setSelectedNode(node);
      setSelectedFolder(node?.id);
      setSelectedNodeLabel(node?.label || "");
    }
  };

  return (
    <>
      <TextField
        label={t("SelectedFolder")}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            color: "#598dd5", // Override the disabled text color
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: "#598dd5", // Fix for some browsers that use this property for text fill color
          },
        }}
        value={selectedNodeLabel}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        margin="normal"
        disabled={true}
      />
      <div className="container-folder-tree-structure">
        <RichTreeView
          items={treeData}
          selectedItems={selectedNodeId}
          onSelectedItemsChange={handleSelectedItemsChange}
          multiSelect={false}
          getItemId={getItemId}
        />
      </div>
    </>
  );
};

export default FolderTreeStructure;
