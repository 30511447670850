import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Compose from "./core/context/ComposeContext";
import { AuthContextProvider } from "./core/context/AuthContext";
import { AppContextProvider } from "./core/context/AppContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./core/custome-styling/Mui";
import { FabricJSContextProviderNew } from "./core/context/FabricJSContextNew";
import "./ii18n";
import { ThemeContextProvider } from "./core/context/ThemeContext";
import NotificationProvider from "./core/context/notifications/NotificationProvider";
import { ProductContextProvider } from "./core/context/ProductContext";
import { ProjectContextProvider } from "./core/context/ProjectContext";
import { GraphicsContextProvider } from "./core/context/GraphicsContext";
import { TextContextProvider } from "./core/context/TextContext";
import { DragDropContext } from "react-beautiful-dnd";
//worker set up for pdf conversion

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ThemeProvider theme={theme}>
    <Compose
      components={[
        AuthContextProvider,
        AppContextProvider,
        FabricJSContextProviderNew,
        ThemeContextProvider,
        NotificationProvider,
        ProductContextProvider,
        ProjectContextProvider,
        GraphicsContextProvider,
        TextContextProvider,
      ]}
    >
      <App />
    </Compose>
  </ThemeProvider>
);

reportWebVitals();
