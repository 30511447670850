import { updateCanvasObjectsList } from "../UpdateCanvasObjectList";

const deleteObject = (
  canvas: any,
  selectedObjId: string,
  setCanvasObjectList: any,
  sendRequest?: any
) => {
  if (canvas) {
    const objects = canvas.getObjects();
    const objectToRemove = objects.find((obj: any) => obj.id === selectedObjId);

    if (objectToRemove) {
      // Check if the object to remove is a level 4 object
      if (objectToRemove.level === 4) {
        // Find the clone of the level 4 object
        const cloneId = `${objectToRemove.id}-clone`;
        const cloneObject = objects.find((obj: any) => obj.id === cloneId);

        // Remove both the original level 4 object and its clone
        if (cloneObject) {
          canvas.remove(cloneObject);
        }
        canvas.remove(objectToRemove);
      } else {
        // Delete the selected object (assuming it's not level 4 here)
        canvas.remove(objectToRemove);
      }
    }

    updateCanvasObjectsList(canvas, setCanvasObjectList);
    canvas.renderAll();
  }
};

export default deleteObject;
