import React, { useState } from "react";
import s from "./GraphicComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
import SelectOptionWrapper from "../SelectOption/SelectOptionWrapper";
import { useApiRequest } from "../../core/api/useApiRequest";
import { uniqueID, useNotification } from "../../core/context/notifications/NotificationProvider";
import { useTranslation } from "react-i18next";
import NormalModal from "../Modals/Modal/NormalModal";
import ModalWrapper from "../Modals/Modal.wrapper";
import ChangeName from "../Modals/ChangeName/ChangeName";
interface IGraphicComponent {
  variant: string;
  handleClick: () => void;
  id: string;
  imgSrc: string;
  componentOptions: string;
  onDelete?: (id: number) => void; // Add onDelete prop
  graphic: any;
}
const selectStyle = {
  position: "absolute",
  bottom: " 35px",
  left: "-3px",
};
const GraphicComponent = ({
  handleClick,
  variant,
  imgSrc,
  id,
  componentOptions,
  onDelete,
  graphic,
}: IGraphicComponent) => {
  const [openMore, setOpenMore] = useState<boolean>(false);
  const [openChangeNameModal, setOpenChangeNameModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { sendRequest } = useApiRequest();
  const notification = useNotification();
  const deleteGraphic = async () => {
    try {
      const response = await sendRequest("delete", `/creator/2d/templates/${graphic.id}`);
      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Product Deleted",
      });

      if (onDelete) {
        onDelete(graphic.id);
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setOpenMore((prevState) => !prevState);
    }
  };
  const changeName = async () => {
    setOpenChangeNameModal((prevState) => !prevState);
  };
  const options = [
    {
      function: deleteGraphic,
      name: t("Delete"),
    },
    {
      function: changeName,
      name: t("ChangeNameModal.Header"),
    },
  ];
  return (
    <>
      <div
        className={`${s.container} ${
          variant === "2ex_small"
            ? s.two_ex_small
            : variant === "ex_small"
            ? s.ex_small
            : variant === "small"
            ? s.small
            : variant === "medium"
            ? s.medium
            : s.large
        }  `}
        onClick={() => handleClick()}
      >
        <ComponentBackground>
          <div
            className={`${s.img_container} ${
              variant === "2ex_small"
                ? s.two_ex_small
                : variant === "ex_small"
                ? s.ex_small
                : variant === "small"
                ? s.small
                : variant === "medium"
                ? s.medium
                : s.large
            }  `}
          >
            <img src={imgSrc} alt={id} />
          </div>
        </ComponentBackground>
        {componentOptions === "1" ? (
          <SelectOptionWrapper
            options={options}
            openMore={openMore}
            setOpenMore={setOpenMore}
            style={selectStyle}
          />
        ) : null}

        <div className={s.info}>
          <span>{graphic?.name}</span>
          <span></span>
        </div>
      </div>
      <NormalModal open={openChangeNameModal} setOpen={setOpenChangeNameModal}>
        <ModalWrapper setOpen={setOpenChangeNameModal}>
          <ChangeName
            url={"templates"}
            currentJSON={graphic}
            setCloseModal={setOpenChangeNameModal}
          />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default GraphicComponent;
