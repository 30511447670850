import { updateCanvasObjectsList } from "./UpdateCanvasObjectList";

const lockObject = (canvas: any, objectId: string, setCanvasObjectList: any) => {
  if (canvas) {
    // Find the object with the given id
    const objectToToggle = canvas.getObjects().find((obj: any) => obj.id === objectId);

    if (objectToToggle) {
      const isLocked = objectToToggle.lockMovementX;

      if (isLocked) {
        // Unlocking the object and making it selectable
        objectToToggle.set({
          lockMovementX: false,
          lockMovementY: false,
          lockRotation: false,
          lockScalingX: false,
          lockScalingY: false,
          hasControls: true,
          hasBorders: true,
          selectable: true,
        });
      } else {
        // Locking the object and making it non-selectable
        objectToToggle.set({
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          hasControls: false,
          hasBorders: false,
          selectable: false,
        });
      }
      updateCanvasObjectsList(canvas, setCanvasObjectList);
      canvas.renderAll();
    }
  }
};
export default lockObject;
