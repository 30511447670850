import React, { useContext, useEffect, useState } from "react";
import s from "../EditSettings.module.scss";
import ModalHeader from "../../ModalHeader";
import { TextField } from "@mui/material";
import SelectLanguage from "../../../SelectLanguage/SelectLanguage";
import { useTranslation } from "react-i18next";
import { usePostRequest } from "../../../../core/api/usePostRequest";
import Button from "../../../Button/Button";
import AuthContext from "../../../../core/context/AuthContext";
import { isEmptyString, validateEmail } from "../../../../helper/validation";
import {
  uniqueID,
  useNotification,
} from "../../../../core/context/notifications/NotificationProvider";
import { useApiRequest } from "../../../../core/api/useApiRequest";

const buttonStyle = { marginLeft: "auto", width: "200px" };

const defaultValues = {
  name: "",
  email: "",
};

interface IEditProfile {
  setCloseModal: any;
}

const EditProfile = ({ setCloseModal }: IEditProfile) => {
  const { t } = useTranslation();
  const { sendRequest, isLoading } = useApiRequest();
  const notification = useNotification();
  const [input, setInput] = useState(defaultValues);
  const { setUser, user } = useContext(AuthContext);

  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    if (user) {
      const updatedValues = {
        name: user.name || "",
        email: user.email || "",
        lang: user.lang || "",
      };
      setInput((prev) => ({ ...prev, ...updatedValues }));
    }
  }, [user]);

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handlePostData = async () => {
    if (!formIsValid()) return;

    const response: any = await sendRequest("post", "/account/profile", input);
    console.log(response, "response data edit profile");
    if (!response) return;
    setUser((prev: any) => ({
      ...prev,
      name: response?.data?.name,
      email: response?.data?.email,
    }));

    notification({ type: "SUCCESS", message: t("Success"), id: uniqueID() });
    setCloseModal((prev: boolean) => !prev);
  };

  const formIsValid = () => {
    return !isEmptyString(input.name) && validateEmail(input.email);
  };

  const validateField = (
    value: string,
    validationFunc: (value: string) => boolean,
    setValidState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setValidState(validationFunc(value));
  };

  return (
    <div className={s.container}>
      <ModalHeader header={t("Edit profile")} />
      <TextField
        value={input.name}
        onChange={(e) => inputHandler(e)}
        name="name"
        label={t("User name")}
        onBlur={() =>
          validateField(input.name, (value) => !isEmptyString(value), setIsUsernameValid)
        }
        error={!isUsernameValid}
      />
      <TextField
        value={input.email}
        onChange={(e) => inputHandler(e)}
        name="email"
        label={t("Address email")}
        onBlur={() => validateField(input.email, validateEmail, setIsEmailValid)}
        error={!isEmailValid}
      />
      <SelectLanguage setInput={setInput} />
      <Button
        text={isLoading ? t("Loading") : "Send"}
        type="button"
        handleClick={handlePostData}
        styles={buttonStyle}
        disabled={!formIsValid()}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditProfile;
