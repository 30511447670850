import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { useTranslation } from "react-i18next";
import s from "./SelectLanguage.module.scss";
import AuthContext from "../../core/context/AuthContext";
interface ISelectLanguage {
  setInput: any;
}
const SelectLanguage = ({ setInput }: any) => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AuthContext);
  const countries = [
    {
      code: "GB" as FlagIconCode,
      label: t("LanguageComponent.English"),
      lang: "en",
    },
    {
      code: "PL" as FlagIconCode,
      label: t("LanguageComponent.Polish"),
      lang: "pl",
    },
    {
      code: "DE" as FlagIconCode,
      label: t("LanguageComponent.German"),
      lang: "de",
    },
    // Add more countries as needed
  ];
  const [currentLanguage, setCurrentLanguage] = useState(() =>
    countries.find((country) => country.lang === i18n.language)
  );

  const handleLanguageChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    if (newValue && newValue.lang) {
      i18n.changeLanguage(newValue.lang);
      setCurrentLanguage(newValue);
      setInput((prev: any) => ({
        ...prev,
        lang: newValue.lang,
      }));
    }
  };

  // Update the current language in state if i18n.language changes
  useEffect(() => {
    const newCurrentLanguage: any = countries.find((country) => country.lang === i18n.language);
    setCurrentLanguage(newCurrentLanguage);
    setInput((prev: any) => ({
      ...prev,
      lang: newCurrentLanguage.lang,
    }));
  }, [i18n.language]);
  return (
    <div className={s.container} style={{ width: "100%" }}>
      <Autocomplete
        id="country-select-demo"
        value={user.lang}
        options={countries}
        autoHighlight
        getOptionLabel={(option) => option.label}
        onChange={handleLanguageChange}
        autoComplete={false}
        renderOption={(props, option) => (
          <li {...props} className={s.img_container}>
            <span style={{ marginLeft: 10 }}>{option.label}</span>
            <FlagIcon code={option.code} size={20} style={{ borderRadius: "50%" }} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Choose a language")}
            inputProps={{
              ...params.inputProps,
              autoComplete: false, // Disable autocomplete and autofill
            }}
          />
        )}
      />
    </div>
  );
};

export default SelectLanguage;
