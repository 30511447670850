import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./Folder.module.scss";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SelectOption from "../../SelectOption/SelectOption";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { useApiRequest } from "../../../core/api/useApiRequest";
import NormalModal from "../../Modals/Modal/NormalModal";
import ModalWrapper from "../../Modals/Modal.wrapper";
import CreateFolder from "../../Modals/FolderModals/CreateFolder/CreateFolder";
import EditNameFolder from "../../Modals/FolderModals/EditNameFolder/EditNameFolder";
import { IFolderData } from "../../../types/generalTypes";
import { useTranslation } from "react-i18next";
import DeleteFolder from "../../Modals/FolderModals/DeleteFolder/DeleteFolder";
import AppContext from "../../../core/context/AppContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface IFolder {
  handleClick: () => void;
  folder: IFolderData;
  path: string;
  customeStyle?: React.CSSProperties;
  withTreeList?: boolean;
  isTreeVisible?: boolean;
}
const selectStyle = { position: "absolute", top: "-100px", left: "-140px" };

const Folder = ({
  handleClick,
  folder,
  path,
  customeStyle,
  withTreeList,
  isTreeVisible,
}: IFolder) => {
  const [changeNameModal, setChangeNameModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const { t } = useTranslation();
  const { setCurrentFolder } = useContext(AppContext);

  const handleOpenChangeNameModal = () => setChangeNameModal(true);
  const handleOpenDeleteModal = () => setDeleteModal(true);

  const options = [
    {
      function: handleOpenChangeNameModal,
      name: t("Change name"),
    },
    {
      function: handleOpenDeleteModal,
      name: t("Delete"),
    },
  ];

  const showOptionsHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, el: IFolderData) => {
    e.stopPropagation();
    setCurrentFolder(el);
    setShowOptions((prev) => !prev);
  };
  const folderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (folderRef.current && !folderRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [folderRef]);

  return (
    <>
      <div
        ref={folderRef}
        className={s.container}
        onClick={() => handleClick()}
        style={customeStyle}
      >
        <div className={s.top}></div>
        <div className={s.bottom}>
          <span>{folder?.name || folder?.label} </span>
          {!withTreeList ? (
            <div>
              <MoreHorizIcon onClick={(e) => showOptionsHandler(e, folder)} />

              {showOptions ? <SelectOption options={options} style={selectStyle} /> : null}
            </div>
          ) : (
            <KeyboardArrowDownIcon className={` ${s.arrow} ${isTreeVisible ? s.open : ""} `} />
          )}
        </div>
      </div>
      <NormalModal open={changeNameModal} setOpen={setChangeNameModal}>
        <ModalWrapper setOpen={setChangeNameModal}>
          <EditNameFolder setCloseModal={setChangeNameModal} />
        </ModalWrapper>
      </NormalModal>
      <NormalModal open={deleteModal} setOpen={setDeleteModal}>
        <ModalWrapper setOpen={setDeleteModal}>
          <DeleteFolder setCloseModal={setDeleteModal} path={path} folder={folder} />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default Folder;
