import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./ProjectComponent.module.scss";
import ComponentBackground from "../ComponentBackground/ComponentBackground";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import SwiperCore from "swiper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../core/api/useApiRequest";
import SelectOptionWrapper from "../SelectOption/SelectOptionWrapper";
import ProjectContext from "../../core/context/ProjectContext";
import { uniqueID, useNotification } from "../../core/context/notifications/NotificationProvider";
import noImageAvailable from "../../assets/images/images.png";
import BackHandIcon from "@mui/icons-material/BackHand";
import AppContext from "../../core/context/AppContext";

interface Variant {
  img_preview: string;
  name: string;
  size: string;
}

interface IprojectComponent {
  project: any;
  handleClick: (variant: Variant | null) => void;
  variant: "2ex_small" | "ex_small" | "small" | "medium" | "large";
  id?: string;
  componentOptions: string;
  onDelete?: (id: number) => void; // Add onDelete prop
}
const selectStyle = {
  position: "absolute",
  bottom: " 35px",
  left: "-3px",
};
const ProjectComponent: React.FC<IprojectComponent> = ({
  project,
  handleClick,
  variant,
  id,
  componentOptions,
  onDelete,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const { setForceFetchReload } = useContext(AppContext);
  const [openMore, setOpenMore] = useState<boolean>(false);

  const { t } = useTranslation();
  const { sendRequest } = useApiRequest();

  const notification = useNotification();

  const deleteProject = async () => {
    try {
      const response = await sendRequest("delete", `/creator/2d/projects/${project.id}`);
      notification({
        id: uniqueID,
        type: "SUCCESS",
        message: "Project Deleted",
      });
      if (onDelete) {
        onDelete(project.id);
        setForceFetchReload((prev: boolean) => !prev);
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setOpenMore((prevState) => !prevState);
    }
  };
  const options = [
    {
      function: deleteProject,
      name: t("Delete"),
    },
  ];
  // console.log({ project });
  const handleSelectProject = (selectedView: any, event: any) => {
    event.stopPropagation();
    // console.log(selectedView, "selectedView");
    handleClick(selectedView);
  };

  const goNext = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    swiperRef.current?.slideNext();
  };

  const goPrev = (event: React.MouseEvent<SVGSVGElement>) => {
    event?.stopPropagation();
    swiperRef?.current?.slidePrev();
  };

  const handleSlideChange = () => {};
  return (
    <div
      className={`${s.container} ${
        variant === "2ex_small"
          ? s.two_ex_small
          : variant === "ex_small"
          ? s.ex_small
          : variant === "small"
          ? s.small
          : variant === "medium"
          ? s.medium
          : s.large
      }  `}
    >
      <ComponentBackground id={id}>
        <div
          className={`${s.img_container} ${
            variant === "2ex_small"
              ? s.two_ex_small
              : variant === "ex_small"
              ? s.ex_small
              : variant === "small"
              ? s.small
              : variant === "medium"
              ? s.medium
              : s.large
          }`}
        >
          <Swiper
            modules={[Navigation]}
            className="mySwiper"
            onSwiper={(swiper: any) => (swiperRef.current = swiper)}
            onSlideChange={handleSlideChange} // Handle slide changes
          >
            {project?.versions?.map((el: any, i: any) => (
              <SwiperSlide
                className={s.swiper_slide}
                key={`dashboard-project-variant-${i}`}
                // onClick={(e) => handleSelectProject(project.template.views[i], e)}                // onClick={(e) => handleSelectProject(project.template.views[i], e)}
                onClick={(e) => handleSelectProject(el, e)}
              >
                <img src={el?.thumbnail ? el.thumbnail : noImageAvailable} alt={el?.name} />
              </SwiperSlide>
            ))}
          </Swiper>
          {project?.versions?.length > 1 ? (
            <>
              <ChevronLeftIcon className={s.l} fontSize="large" onClick={goPrev} />
              <ChevronRightIcon className={s.r} fontSize="large" onClick={goNext} />
            </>
          ) : null}
        </div>
      </ComponentBackground>
      {componentOptions === "1" ? (
        <SelectOptionWrapper
          options={options}
          openMore={openMore}
          setOpenMore={setOpenMore}
          style={selectStyle}
        />
      ) : null}

      <div className={s.info}>
        <span>{project?.name}</span>
        <span></span>
      </div>
      <BackHandIcon className={s.hand_icon} />
    </div>
  );
};

export default ProjectComponent;
