import React, { useContext, useState, useEffect, useCallback } from "react";
import s from "./WorkShopProject.module.scss";
import AddLayers from "./components/AddLayers";
import GetQuoteModal from "../../../../../../components/Modals/GetQuote/GetQuoteModal";
import ModalWrapper from "../../../../../../components/Modals/Modal.wrapper";
import NormalModal from "../../../../../../components/Modals/Modal/NormalModal";
import FabricJSContextNew from "../../../../../../core/context/FabricJSContextNew";
import Button from "../../../../../../components/Button/Button";
import RenderLayers from "./components/RenderLayers";
import DevMode from "./components/DevMode";
import { useTranslation } from "react-i18next";

const buttonStyle = {
  marginTop: "auto",
};

const WorkShopProject = () => {
  const { canvasObjectList, activeGlobalState } = useContext(FabricJSContextNew);
  const [seeAddLayers, setSeeAddLayers] = useState<boolean>(true);
  const [openGetQuoteModal, setOpenGetQuoteModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const canvasObjectListFilter = () => {
    const listToShow = canvasObjectList.filter(
      (o: any) => o.level !== 1 && o.level !== 2 && o.level !== 5
    );
    return listToShow;
  };

  const filterObjectsForAddLayers = useCallback(() => {
    const hasLevel4Objects = canvasObjectList?.some((el: any) => el.level === 4);
    setSeeAddLayers(!hasLevel4Objects);
  }, [canvasObjectList]);

  useEffect(() => {
    filterObjectsForAddLayers();
  }, [canvasObjectList, filterObjectsForAddLayers]);

  const [dev, setDev] = useState(false);

  return (
    <>
      <div className={s.container}>
        <h4>warstwy</h4>
        <div>
          <RenderLayers canvasObjectListFilter={canvasObjectListFilter} />
        </div>
        {canvasObjectListFilter().length <= 0 && <AddLayers />}
        <Button
          text={t("GetQuoteModal.Header")}
          type="button"
          styles={buttonStyle}
          handleClick={() => setOpenGetQuoteModal(!openGetQuoteModal)}
        />

        <button onClick={() => setDev(!dev)} style={{ width: "10%" }}>
          DEV
        </button>
        {dev && <DevMode />}
      </div>
      <NormalModal open={openGetQuoteModal} setOpen={setOpenGetQuoteModal}>
        <ModalWrapper setOpen={setOpenGetQuoteModal}>
          <GetQuoteModal setCloseModal={setOpenGetQuoteModal} />
        </ModalWrapper>
      </NormalModal>
    </>
  );
};

export default WorkShopProject;
