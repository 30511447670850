import React, { useCallback, useContext } from "react";
import s from "./RenderLayers.module.scss";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import { Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import changeObjectName from "../../../../../fabric-functions/ManipulateObjects/ChangeObjectName";
import hideObject from "../../../../../fabric-functions/ManipulateObjects/HideObject";
import lockObject from "../../../../../fabric-functions/LockObject";
import { reorderCanvasObjects } from "../../../../../fabric-functions/UpdateCanvasObjectList";
import deleteObject from "../../../../../fabric-functions/ManipulateObjects/DeleteObject";
import duplicateCanvasObject from "../../../../../fabric-functions/ManipulateObjects/DuplicateObject";

const RenderLayers: React.FC<{ canvasObjectListFilter: any }> = ({ canvasObjectListFilter }) => {
  const { canvasObjectList, setCanvasObjectList, canvasContext } = useContext(
    FabricJSContextNew
  ) as any;

  const handleObjectNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, objectId: string) => {
      changeObjectName(canvasContext, objectId, e.target.value, setCanvasObjectList);
    },
    [canvasContext, setCanvasObjectList]
  );

  const handleToggleHideObject = useCallback(
    (objectId: string) => {
      hideObject(canvasContext, objectId, setCanvasObjectList);
    },
    [canvasContext, setCanvasObjectList]
  );

  const handleToggleLockObjectById = useCallback(
    (objectId: string) => {
      lockObject(canvasContext, objectId, setCanvasObjectList);
    },
    [canvasContext, setCanvasObjectList]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const items = Array.from(canvasObjectList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setCanvasObjectList(items);
      reorderCanvasObjects(canvasContext, items);
    },
    [canvasObjectList, setCanvasObjectList, canvasContext]
  );

  const handleDeleteObject = useCallback(
    async (selectedObjId: string) => {
      deleteObject(canvasContext, selectedObjId, setCanvasObjectList);
    },
    [canvasContext, setCanvasObjectList]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="layers"
        isDropDisabled={false}
        isCombineEnabled={false}
        ignoreContainerClipping={false}
      >
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {canvasObjectList
              .filter((obj: any) => obj)
              .map((el: any, i: number) =>
                el.level === 4 ? (
                  <Draggable key={el.id} draggableId={el.id} index={i}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={s.layer_container}
                      >
                        {el?.id?.includes("-img") ? <ImageIcon /> : <FormatBoldIcon />}
                        <input
                          type="text"
                          placeholder={el?.type.includes("text") ? el.text : el.name}
                          value={el?.type.includes("text") ? el.text : el.name}
                          onChange={(e) => handleObjectNameChange(e, el.id)}
                        />

                        <div className={s.icons}>
                          <Tooltip placement="top" title="Ukryj">
                            <VisibilityIcon onClick={() => handleToggleHideObject(el.id)} />
                          </Tooltip>
                          <Tooltip placement="top" title="Duplikuj">
                            <ContentCopyIcon
                              onClick={() =>
                                duplicateCanvasObject(
                                  canvasContext,
                                  el.id,
                                  setCanvasObjectList,
                                  el.level
                                )
                              }
                            />
                          </Tooltip>
                          {el.hasControls ? (
                            <Tooltip placement="top" title="Zablokuj">
                              <LockIcon onClick={() => handleToggleLockObjectById(el.id)} />
                            </Tooltip>
                          ) : (
                            <Tooltip placement="top" title="Odblokuj">
                              <LockOpenIcon onClick={() => handleToggleLockObjectById(el.id)} />
                            </Tooltip>
                          )}
                          <Tooltip placement="top" title="Usuń">
                            <DeleteIcon
                              className="bin-icon-mui"
                              onClick={() => handleDeleteObject(el.id)}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ) : null
              )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default RenderLayers;
