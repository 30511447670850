import React, { useContext, useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import s from "./AddLayers.module.scss";
import { Tooltip } from "@mui/material";
import SlideUpModal from "../../../../../../../components/Modals/Modal/SildeUpModal";
import AddImage from "./AddImage";
import FabricJSContextNew from "../../../../../../../core/context/FabricJSContextNew";
import addGateway from "../../../../../fabric-functions/AddToCanvas/AddGateway";

const AddLayers = () => {
  const [addImageOpen, setAddImageOpen] = useState<boolean>(false);
  const { setCanvasObjectList, canvasContext, setCanvasJSONContext } =
    useContext(FabricJSContextNew);

  const handleAddText = (text: string) => {
    const defaultTextData = {
      text,
      fontSize: 16,
      color: "#000000",
      fileType: "text",
      level: 4,
    };
    addGateway(canvasContext, defaultTextData, setCanvasObjectList);
  };

  const handleOpen = () => setAddImageOpen(true);
  const handleClose = () => setAddImageOpen(false);
  return (
    <>
      <div className={s.container}>
        <p>
          <strong>Brak warstw. </strong>Kliknij wybrany przycisk znajdujący się poniżej
          <br /> lub u dołu strony aby dodać <strong>obraz</strong> lub <strong>tekst</strong> do
          projektu.
        </p>

        <div className={s.img_container}>
          <Tooltip title="Add Image" placement="top">
            <label htmlFor="fileInput">
              <div onClick={() => setAddImageOpen(true)}>
                <ImageIcon fontSize="large" />
              </div>
            </label>
          </Tooltip>
          <Tooltip title="Add Text" placement="top">
            <div onClick={() => handleAddText("text")}>
              <TextIncreaseIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      <SlideUpModal open={addImageOpen} onClose={() => setAddImageOpen(false)}>
        <AddImage closeModal={handleClose} />
      </SlideUpModal>
    </>
  );
};

export default AddLayers;
