import React from "react";
import s from "./Domains.module.scss";
const helper = ["Hosting", "Adres URL", "DNS", "DNS"];
const apiResponse = [
  "The Camels",
  "videlink.eu",
  "ns1.thecamels.org",
  "ns2.thecamels.org",
];
const Domain = () => {
  return (
    <div className={s.container}>
      <h3>Udostępnij narzędzie videlink pod własną domeną</h3>
      <div className={s.table}>
        <div className={s.top}>
          {helper.map((el, i) => (
            <div>
              <span>{el}</span>
              <span>{apiResponse[i]}</span>
            </div>
          ))}
        </div>
        <div className={s.bottom}>
          <span className={s.other}>Zobacz</span>
          <span>jak pozyskać te dane.</span>
          <span>Edytuj</span>
        </div>
      </div>
    </div>
  );
};

export default Domain;
