import { fabric } from "fabric";
import { serializeObject } from "./serializeObject";

// Define properties specific to your implementation
type TextProps = Partial<
  Pick<
    fabric.IText,
    | "text"
    | "fontSize"
    | "fontWeight"
    | "fontStyle"
    | "textAlign"
    | "fill"
    | "underline"
    | "fontFamily"
  >
>;

type CanvasActiveObjectState = {
  objectJSON: fabric.IText | null;
  newProperties: Record<string, unknown>;
};

const changeText = (canvas: fabric.Canvas, newTextProps: TextProps, setCanvasActiveObject: any) => {
  const activeObject = canvas.getActiveObject() as fabric.IText | null;

  if (activeObject && activeObject.type === "i-text") {
    const validProps = Object.keys(newTextProps).filter(
      (key) => key in activeObject
    ) as (keyof fabric.IText)[];

    validProps.forEach((key) => {
      activeObject.set(key, newTextProps[key as keyof TextProps]);
    });

    const cloneId = `${activeObject.id}-clone`;
    const clone = canvas.getObjects().find((o) => o.id === cloneId) as any;

    if (clone) {
      Object.keys(newTextProps).forEach((prop) => {
        const key = prop as keyof TextProps;
        clone.set(key, newTextProps[key]);
      });
    }
    // Re-render the canvas
    canvas.renderAll();
    const sterylizedActiveobject = serializeObject(activeObject);
    setCanvasActiveObject((prev: any) => ({
      ...prev,
      objectJSON: sterylizedActiveobject,
    }));
  }
};

export default changeText;
