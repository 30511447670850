import { fabric } from "fabric";

const takeCanvasSnapshot = (canvas: any, setActiveGlobalState: any) => {
  if (!canvas) {
    console.log("no canvas returned");
    return;
  }
  // Save the current viewportTransform
  const originalViewportTransform = canvas.viewportTransform.slice();

  // Reset the viewportTransform to identity matrix (no zoom or pan)
  canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);

  let minX = Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let maxX = -Number.MAX_VALUE;
  let maxY = -Number.MAX_VALUE;

  canvas.forEachObject((obj: any) => {
    // Skip any objects if necessary
    // if (obj.name === "redCircle") return;

    // Get the bounding rectangle considering transformations
    const boundingRect = obj.getBoundingRect(true);

    minX = Math.min(minX, boundingRect.left);
    minY = Math.min(minY, boundingRect.top);
    maxX = Math.max(maxX, boundingRect.left + boundingRect.width);
    maxY = Math.max(maxY, boundingRect.top + boundingRect.height);
  });

  const width = maxX - minX;
  const height = maxY - minY;

  // Take the snapshot using the calculated bounding box
  const snapshotDataURL = canvas.toDataURL({
    format: "png",
    quality: 1,
    left: minX,
    top: minY,
    width: width,
    height: height,
  });

  // Restore the original viewportTransform
  canvas.setViewportTransform(originalViewportTransform);

  canvas.renderAll();

  // Update the global state with the snapshot
  setActiveGlobalState((prevState: any) => ({
    ...prevState,
    generateThumbnail: snapshotDataURL,
  }));
};

export default takeCanvasSnapshot;
