import React from "react";
import { LeftBarImageI } from "../../../../types/generalTypes";
interface TextT {
  isActive: boolean;
}
const Text = ({ isActive, isLoggedIn }: LeftBarImageI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.918"
      height="25.918"
      viewBox="0 0 25.918 25.918"
    >
      <path
        id="Path_4347"
        data-name="Path 4347"
        d="M756.959,349.918h7.559v-2.16h-3.24a2.115,2.115,0,0,1-2.16-2.16V327.24H765.6c2.16,0,2.16,4.32,2.16,4.32h2.16V324H744v7.559h2.16s0-4.32,2.16-4.32H754.8V345.6a2.115,2.115,0,0,1-2.16,2.16H749.4v2.16Zm0,0"
        transform="translate(-744 -324)"
        fill={!isActive ? "#ffff" : isLoggedIn ? "#808d98" : "#808d98"}
      />
    </svg>
  );
};

export default Text;
