import React, { ReactNode, useContext } from "react";
import Breadcrumbs from "../components/Nav/Breadcrumbs/Breadcrumbs";
import s from "./Layout.module.scss";
import TopBar from "../components/Nav/TopBar/TopBar";
import MainNav from "../components/Nav/MainNav/MainNav";
import LeftBar from "../components/Nav/LeftBar/LeftBar";
import AppContext from "../core/context/AppContext";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useApiRequest } from "../core/api/useApiRequest";
interface ILayout {
  children: ReactNode;
}
const Layout = ({ children }: ILayout) => {
  const { folderPath, setFolderPath, setForceFetchFolder } = useContext(AppContext);
  const { sendRequest } = useApiRequest();
  const handleDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId, type } = result;

    if (!destination) return;

    // If dropped in the same place, do nothing
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Determine the type of drag based on droppableId patterns or other metadata
    if (destination.droppableId.startsWith("folder-")) {
      await handleMoveToFolder(draggableId, destination.droppableId);
    } else if (destination.droppableId === "product-list") {
    }
    // Add more conditions if you have other droppable areas
  };

  const handleMoveToFolder = async (draggableId: string, droppableId: string) => {
    const folderId = droppableId.replace("folder-", "");
    const productId = draggableId.replace("product-", "");
    const response = await sendRequest("put", `creator/2d/templates/${productId}`, {
      category_id: folderId,
    });
    setForceFetchFolder((prevState) => !prevState);

    console.log(`Product ${productId} dropped on Folder ${folderId}`, response);
  };
  return (
    <div className={s.container}>
      <TopBar />
      <div className={s.children}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <MainNav />
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%", marginLeft: "10px" }}
          >
            <Breadcrumbs styleOption={1} folderPath={folderPath} setFolderPath={setFolderPath} />
            {children}
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Layout;
