import { updateCanvasObjectsList } from "../UpdateCanvasObjectList";

const changeObjectName = (
  canvas: any,
  objectId: any,
  newName: string,
  setCanvasObjectList: any
) => {
  if (canvas) {
    canvas.getObjects().forEach((obj: any) => {
      if (obj.id === objectId || obj.id === `${objectId}-clone`) {
        if (obj.type.includes("text")) {
          obj.set({ text: obj.id === objectId ? newName : `${newName}` });
        } else {
          obj.set({ name: obj.id === objectId ? newName : `${newName}-clone` });
        }
      }
    });

    updateCanvasObjectsList(canvas, setCanvasObjectList);
    canvas.renderAll();
  }
};

export default changeObjectName;
