import React, { createContext, useState, ReactNode, useEffect, useContext } from "react";

import AuthContext from "./AuthContext";

interface Theme {
  [key: string]: string;
}

interface User {
  theme: Theme;
}

interface ThemeContextValue {
  theme: Theme | null;
  setTheme: React.Dispatch<React.SetStateAction<Theme | null>>;
}

const defaultContextValueNew: ThemeContextValue = {
  theme: null,
  setTheme: () => {},
};

const ThemeContext = createContext<ThemeContextValue>(defaultContextValueNew);

interface IThemeContextProviderProps {
  children: ReactNode;
}

export const ThemeContextProvider: React.FC<IThemeContextProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme | null>(null);
  const { user } = useContext(AuthContext);
  // Mock user object, replace with the actual user context or data fetching logic
  //   const user: User = {
  //     theme: {
  //       mainColor: "#ff0000",
  //       // Add other theme properties as needed
  //     },
  //   };

  useEffect(() => {
    if (user && user.theme) {
      setTheme(user.theme);
      // Apply each theme property to the root element
      Object.keys(user.theme).forEach((key) => {
        // Ensure TypeScript understands key is a key of user.theme
        const themeKey = key as keyof Theme;
        const themeValue = user.theme[themeKey];
        document.documentElement.style.setProperty(`--${themeKey}`, themeValue);
      });
    }
  }, [user]);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeContext;
